import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { setZOne, setSideBarClass } from "../store/Slice/userSlice";
import { useDispatch } from 'react-redux';
function SidebarMenu({ menu }) {
    const dispatch = useDispatch();
    const [activeClass, setActiveClass] = useState(0);
    const handlerClick = (key) => {
        setActiveClass(key)
        dispatch(setSideBarClass({ "sidebarClass": "active" }));
    }
    const handlerSpan = (name) => {
        dispatch(setZOne({ zone: name }));
    }
    return (
        <div>{menu.map((item, index) => (
            <li className={`nav-item menu-items ${(activeClass === index) ? "active" : ""}`} key={index} onClick={() => handlerClick(index)}>
                <Link to={item.path} className="nav-link" onClick={() => handlerSpan(item.name)}>
                    <span className="menu-icon">
                        <i className="mdi mdi-playlist-play"></i>
                    </span>
                    <span className="menu-title" >{item.name} </span>
                    <i className="menu-arrow"></i>
                </Link>
            </li>
        ))
        }</div>
    )
}

export default SidebarMenu