import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
function Logout() {
    const navigate = useNavigate();
    let { user, zone } = useSelector((state) => state.user);


    useEffect(() => {
        // Remove the first CSS file
        const firstCSS = document.querySelector('link[href="../../assets/css/style.css"]');
        if (firstCSS) {
            firstCSS.parentNode.removeChild(firstCSS);
        }

        // Add the second CSS file
        const secondCSS = document.createElement("link");
        secondCSS.rel = "stylesheet";
        secondCSS.href = "../../assets/css/front/style.css";
        document.head.appendChild(secondCSS);

        // Cleanup: Remove the second CSS file when the component unmounts
        return () => {
            if (secondCSS.parentNode) {
                secondCSS.parentNode.removeChild(secondCSS);
            }
        };
    }, []);

    useEffect(() => {
        let role = ["S-Manager", "M-Manager", "REP"]
        if (role.includes(user.user.role)) {
            navigate("/");
        } else {
            navigate("/admin-panel/login");
        }
    }, []);
}

export default Logout