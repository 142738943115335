import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllClient, addQizData } from '../../API/api'; // Import the specific API function
import { useSelector } from "react-redux";
import { loadScript } from '../../utils/loadScript';
import * as XLSX from "xlsx";

function AddQuiz() {
    const [apierror, setApiErro] = useState();
    const user = useSelector((state) => state.user.user); // Access the user object
    const [options, setOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        "cID": "",
        file: null
    })

    const validateForm = () => {
        let formErrors = {};
        if (!formData.cID) formErrors.cID = "Please select client";
        if (!formData.file) formErrors.file = "Please upload excel file";
        return formErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Clear error message when user starts typing
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: "",
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {

            const file = formData.file;
            const reader = new FileReader();
            reader.onload = async (e) => {
                const binaryData = e.target.result;
                const workbook = XLSX.read(binaryData, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                let passData = {
                    "cID": formData.cID,
                    "fileData": jsonData
                }

                let clientAdd = await addQizData(user.token, passData);
                if (clientAdd.status === 200) {
                    setFormData({
                        file: null,
                        cID: "",
                    });
                    setApiErro(clientAdd.data.message);
                } else {
                    setApiErro(clientAdd.data.message)
                }
            };

            if (file) {
                reader.readAsBinaryString(file);
            }



        }
    };

    const handleFileChange = (e) => {

        setFormData({ ...formData, file: e.target.files[0] });

        if (errors["file"]) {
            setErrors({
                ...errors,
                ["file"]: "",
            });
        }
    };

    useEffect(() => {
        const loadDropdownData = async () => {
            try {
                const clientList = await getAllClient(user.token);

                if (clientList.status === 200) {
                    let cData = clientList.data.data;
                    let finalData = cData.filter(function (c) { return c.status === "Active" })

                    setOptions(finalData);
                } else {
                    setOptions([])
                }
            } catch (err) {
                console.error(err);
            }
        };
        loadDropdownData();

        const loadPageSpecificScripts = async () => {
            await loadScript('../../assets/js/file-upload.js');

        };
        loadPageSpecificScripts();
    }, []);
    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title"> Add Quiz </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {/* <li className="breadcrumb-item"><a href="#">Team</a></li> */}
                        {/* <li className="breadcrumb-item"><Link to="/admin-panel/team" >Team</Link></li> */}
                        {/* <li className="breadcrumb-item active" aria-current="page">Form elements</li> */}
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title text-success">{apierror}</h4>
                            <form className="forms-sample" onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="exampleInputUsername1">Client</label>
                                    <select className="form-select" id="exampleSelectGender" name="cID" value={formData.cID} onChange={handleChange}>
                                        <option value="">Please select client</option>
                                        {options.map((c, index) => (
                                            <option key={index} value={c._id}>{c.name}</option>
                                        ))}

                                    </select>
                                    {errors.cID && <span style={{ color: "red" }}>{errors.cID}</span>}
                                </div>

                                <div className="form-group">
                                    <label>File upload</label>
                                    <input type="file" name="file" className="file-upload-default" onChange={handleFileChange} />
                                    {/* <input type="file" name="file" className="file-upload-default" onChange={handleFileChange} /> */}

                                    <div className="input-group col-xs-12 d-flex align-items-center">
                                        <input type="text" className="form-control file-upload-info" disabled placeholder="Upload Image" />
                                        <span className="input-group-append ms-2">
                                            <button className="file-upload-browse btn btn-primary" type="button">Upload</button>
                                        </span>
                                    </div>
                                    {errors.file && <span style={{ color: "red" }}>{errors.file}</span>}
                                </div>

                                <button type="submit" className="btn btn-primary me-2">Submit</button>
                                <button className="btn btn-dark">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddQuiz