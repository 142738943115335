import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

const BarChart = ({ data, Y_axis_label }) => {

    // Options for the bar chart
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true, // Show or hide legend
                position: "top", // Position of legend: top, bottom, left, right
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Zone", // X-axis label
                },
            },
            y: {
                title: {
                    display: true,
                    text: Y_axis_label, // Y-axis label
                },
                beginAtZero: true, // Ensure the scale starts at 0
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default BarChart;