import { configureStore } from '@reduxjs/toolkit';
import userReducer from './Slice/userSlice'; // Example reducer
import quizReducer from './Slice/quizSlice';
const store = configureStore({
    reducer: {
        user: userReducer, // Add reducers here
        quiz: quizReducer, // Quiz-related state
    },
});

export default store;