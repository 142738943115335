import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAllClient, getQuizQuestionByClientID, quiztoggleStatus, deleteQuizQuestion } from '../../API/api'; // Import the specific API function
import { useSelector, useDispatch } from "react-redux";
function Quiz() {
    const [apiMsg, setApiMSG] = useState();
    const navigate = useNavigate(); // Initialize the navigate function
    const user = useSelector((state) => state.user.user); // Access the user object
    const [options, setOptions] = useState([{ "_id": "", "name": "Please select Client" }]);
    const [client, setClient] = useState()
    const [errors, setErrors] = useState({});
    const [quizQuestion, setQuizQuestion] = useState([]);

    const loadQuizData = async (cID) => {
        try {

            const quizQuestionList = await getQuizQuestionByClientID(user.token, cID);
            if (quizQuestionList.status === 200) {
                let cData = quizQuestionList.data.data;
                setQuizQuestion(cData);
            } else {
                setQuizQuestion([])
            }
        } catch (err) {
            console.error(err);
        }
    };
    const handleChange = async (e) => {
        const { name, value } = e.target;

        setClient(value)
        await loadQuizData(value);
    };

    useEffect(() => {
        const loadDropdownData = async () => {
            try {
                const clientList = await getAllClient(user.token);

                if (clientList.status === 200) {
                    let cData = clientList.data.data;
                    let finalData = cData.filter(function (c) { return c.status === "Active" })
                    setOptions(finalData);
                } else {
                    setOptions([])
                }
            } catch (err) {
                console.error(err);
            }
        };
        loadDropdownData();
    }, [client]);

    const editHandler = async (id) => {
        navigate(`/admin-panel/quiz/add/${id}`);
    }

    const statusHandler = async (id) => {

        let clientRes = await quiztoggleStatus(user.token, id);
        if (clientRes.status === 200) {
            setApiMSG(clientRes.data.message);
        } else {
            setApiMSG(clientRes.data.message)
        }

        loadQuizData(client);
    }

    const deleteQuizQuestionHandler = async (id) => {
        let clientRes = await deleteQuizQuestion(user.token, id);
        if (clientRes.status === 200) {
            setApiMSG(clientRes.data.message);
        } else {
            setApiMSG(clientRes.data.message)
        }
        loadQuizData(client);
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title"> Quiz Question</h3>
                <h3 className="page-title text-success"> {apiMsg}  </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin-panel/quiz/add" >Add</Link></li>
                        {/* <li className="breadcrumb-item active" aria-current="page">Form elements</li> */}
                    </ol>
                </nav>
            </div>
            <div className="form-group">
                <label htmlFor="exampleInputUsername1">Client</label>
                <select className="form-select" id="exampleSelectGender" name="cID" value={client} onChange={handleChange}>
                    <option value="">Please select client</option>
                    {options.map((c, index) => (
                        <option key={index} value={c._id}>{c.name}</option>
                    ))}
                </select>
            </div>
            <div className="row ">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            {/* <h4 className="card-title">Order Status</h4> */}
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th> Question </th>
                                            <th> Option 1 </th>
                                            <th> Option 2 </th>
                                            <th> Option 3 </th>
                                            <th> Option 4 </th>
                                            <th> Answer </th>
                                            <th> Date </th>
                                            <th> Action  </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {quizQuestion.map((c) => (
                                            <tr key={c._id}>
                                                <td >{c.question}</td>
                                                <td >{c.option1}</td>
                                                <td> {c.option2} </td>
                                                <td> {c.option3} </td>
                                                <td> {c.option4} </td>
                                                <td> {c.answer} </td>
                                                <td> {(c.quizDate) ? c["quizDate"].split("T")[0] : ""} </td>
                                                <td>
                                                    <button type="button" onClick={() => editHandler(c._id)} className="btn btn-rounded btn-icon btn-info"   >
                                                        <i className="fa fa-pencil"></i>
                                                    </button>
                                                    <button type="button" onClick={() => statusHandler(c._id)} className={c.status === "Active" ? "btn btn-rounded btn-icon btn-success" : "btn btn-rounded btn-icon btn-danger"}   >
                                                        <i className={c.status === "Active" ? "fa fa-unlock" : "fa fa-lock"}></i>
                                                    </button>

                                                    <button type="button" onClick={() => { deleteQuizQuestionHandler(c._id) }} className="btn btn-inverse-primary btn-rounded btn-icon">
                                                        <i className="mdi mdi-home-outline"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quiz