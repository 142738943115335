import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, } from "react-redux";
import { getAllMManager, deleteMManager, mmanagertoggleStatus } from '../../API/api'; // Import the specific API function

function MManager() {
    const [apiMsg, setApiMSG] = useState();
    const navigate = useNavigate(); // Initialize the navigate function
    const user = useSelector((state) => state.user.user); // Access the user object
    const [mmanagerList, setMManagerList] = useState([]);

    const fetchData = async () => {
        let mData = await getAllMManager(user.token);
        setMManagerList(mData.data.data);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const deleteMManagerHandler = async (id) => {
        let clientRes = await deleteMManager(user.token, id);
        if (clientRes.status === 200) {
            setApiMSG(clientRes.data.message);
        } else {
            setApiMSG(clientRes.data.message)
        }
        fetchData();
    }

    const statusHandler = async (id) => {
        let clientRes = await mmanagertoggleStatus(user.token, id);
        if (clientRes.status === 200) {
            setApiMSG(clientRes.data.message);
        } else {
            setApiMSG(clientRes.data.message)
        }
        fetchData();
    }
    const editHandler = async (id) => {
        navigate(`/admin-panel/m-manager/add/${id}`);
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title"> Marketing Manager  </h3>
                <h3 className="page-title text-success"> {apiMsg}  </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin-panel/m-manager/add" >Add</Link></li>
                        {/* <li className="breadcrumb-item active" aria-current="page">Form elements</li> */}
                    </ol>
                </nav>
            </div>
            <div className="row ">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            {/* <h4 className="card-title">Order Status</h4> */}
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th> Client Name </th>
                                            <th> Manager Name </th>
                                            <th> Phone </th>
                                            <th> Emp. code </th>
                                            <th> Address </th>
                                            <th> Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mmanagerList.map((c) => (
                                            <tr key={c._id}>
                                                <td >{c.clientName}</td>
                                                <td >{c.name}</td>
                                                <td> {c.phone} </td>
                                                <td> {c.empcode} </td>
                                                <td> {c.address} </td>
                                                <td>
                                                    <button type="button" onClick={() => editHandler(c._id)} className="btn btn-rounded btn-icon btn-info"   >
                                                        <i className="fa fa-pencil"></i>
                                                    </button>
                                                    <button type="button" onClick={() => statusHandler(c._id)} className={c.status === "Active" ? "btn btn-rounded btn-icon btn-success" : "btn btn-rounded btn-icon btn-danger"}   >
                                                        <i className={c.status === "Active" ? "fa fa-unlock" : "fa fa-lock"}></i>
                                                    </button>

                                                    <button type="button" onClick={() => { deleteMManagerHandler(c._id) }} className="btn btn-inverse-primary btn-rounded btn-icon">
                                                        <i className="fa fa-trash-o"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MManager