import React, { useEffect, useState } from 'react'

function QuizQuestion({ currentQuestion, handleOptionSelect, activeButton, className, timeLeft, isDisabled }) {


    return (
        <div className="section1_knowlege psd4s1_bg">
            <div className="container">
                <div className="s1_inner  psd4_inner_section">
                    <div className="s1_logo fom">
                        <div className="left">
                            <div className="content">
                                <a href="#"><span><img src="../../assets/images/front/images/Quiz aarow Icon.png" alt="img" /></span>Quiz</a>
                            </div>

                        </div>
                        <div className="center">
                            <div className="images">

                                <h2 className="10sec">{timeLeft}<br /><span>SEC</span></h2>

                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src="../../assets/images/front/images/home1.png" alt="img" />
                            </div>
                        </div>
                    </div>
                    <div className="s1_form_box boxes psd4_border">
                        <div className="s1_fox_bg_img  psd44">
                            <img src="../../assets/images/front/images/home2.png" alt="img" />
                        </div>
                        <div className="psd4_padding">
                            <div className="head-img">
                                <div className="bothsec">
                                    <div className="HEADING psd4_mobileresponsive">
                                        <h1>
                                            {currentQuestion?.question}
                                        </h1>
                                    </div>
                                    <div className="questions">
                                        <div className="im">
                                            <img src="../../assets/images/front/images/Qution mark.png" alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="s4_psd4_main11">
                                    <div className={`ps4_m12 ${activeButton === 1 ? className : ""}`} onClick={() => handleOptionSelect(1)} id="1" disabled={isDisabled}>
                                        <div className="ps4_left">
                                            <p>1</p>
                                        </div>
                                        <div className="ps4_right">
                                            <p> {currentQuestion?.option1}</p>
                                        </div>
                                    </div>
                                    <div className={`ps4_m12 ${activeButton === 2 ? className : ""}`} onClick={() => handleOptionSelect(2)} id="2" disabled={isDisabled}>
                                        <div className="ps4_left">
                                            <p>2</p>
                                        </div>
                                        <div className="ps4_right">
                                            <p> {currentQuestion?.option2}</p>
                                        </div>
                                    </div>
                                    <div className={`ps4_m12 ${activeButton === 3 ? className : ""}`} onClick={() => handleOptionSelect(3)} id="3" disabled={isDisabled}>
                                        <div className="ps4_left">
                                            <p>3</p>
                                        </div>
                                        <div className="ps4_right">
                                            <p> {currentQuestion?.option3}</p>
                                        </div>
                                    </div>
                                    <div className={`ps4_m12 ${activeButton === 4 ? className : ""}`} onClick={() => handleOptionSelect(4)} id="4" disabled={isDisabled}>
                                        <div className="ps4_left">
                                            <p>4</p>
                                        </div>
                                        <div className="ps4_right">
                                            <p> {currentQuestion?.option4}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {className === "" ? <div className="psd4_main_div p4-ppp">
                            <div className="ps4_new_img">
                                <img src="../../assets/images/front/images/psd4.png" alt="image" />
                            </div>
                            <div className="psd_bg_images"><img src="../../assets/images/front/images/gee.png" alt="img" /></div>
                        </div> : className === "bg-green" ? <div className="psd6_main_mai_mian">
                            <div className="s5_Carac_main">
                                <div className="s5_cara">
                                    <img src="../../assets/images/front/images/psd5.png" alt="image" />
                                </div>
                                <div className="psd_5_carac">
                                    <img src="../../assets/images/front/images/yes Icone green patch.png" alt="img" />
                                </div>
                            </div>
                            <div className="haha">
                                <div className="psd_bg_images"><img src="../../assets/images/front/images/gee.png" alt="img" /></div>
                            </div>
                        </div> : <div className="psd6_main_mai_mian">
                            <div className="psd6_carachter_img1">
                                <img src="../../assets/images/front/images/psd6.png" alt="image" />
                            </div>
                            <div className="psd6_bg6_images"><img src="../../assets/images/front/images/gee.png" alt="img" /></div>
                        </div>}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default QuizQuestion