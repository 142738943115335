import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { checkTodayQuizPlayByUser } from '../API/api'; // Import the specific API function
import { useSelector } from 'react-redux';
import Loaders from '../pages/user/Loaders';
function UserFooterMenu({ classname }) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Initialize the navigate function
    let { user } = useSelector((state) => state.user);
    const handlerProfile = async () => {
        setLoading(true);
        let todayplayResult = await checkTodayQuizPlayByUser(user.token, user.user._id);
        setLoading(false);
        if (todayplayResult.status === 200) {
            if (todayplayResult.data.data !== null) {
                navigate("/no-quiz");
            } else {
                navigate("/welcome");
            }
        }
    }
    return (
        <div className={` ${classname}`} style={{ "display": "flex" }} >
            {loading && <Loaders />}
            <div className="s1_psd2_1">
                <Link to="/home"><img
                    src="../../assets/images/front/images/Home Icon.png"
                    alt="images" />
                </Link>
            </div>
            <div className="s1_psd2_2">
                <a href="#"><img src="../../assets/images/front/images/Star.png" alt="img" /></a>
            </div>
            <div className="s1_psd2_3" onClick={() => handlerProfile()}>
                <Link to="/home" onClick={() => handlerProfile()}><img
                    src="../../assets/images/front/images/Contact Icon.png"
                    alt="images" />
                </Link>

            </div>
        </div>
    )
}

export default UserFooterMenu