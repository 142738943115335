import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

const DoughnutChart = ({ chartData }) => {
    const chartRef = useRef(null); // Reference to the canvas element
    const chartInstanceRef = useRef(null); // Reference to the Chart.js instance

    const formatedData = chartData['data'].map(num => parseFloat(parseFloat(num).toFixed(2)))

    useEffect(() => {
        // Initialize the chart
        const ctx = chartRef.current.getContext("2d");
        chartInstanceRef.current = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: chartData.labels,
                datasets: [{
                    //data: chartData.data,
                    data: formatedData,
                    backgroundColor: ["#FC424A", "#00d25b", "#ffab00", "#0090E7", "#8F5FE8", "#CFF4FC"],
                    borderColor: "#191c24"
                }]
            },
            options: {
                cutout: 70,
                animation: {
                    animateRotate: true,
                    animateScale: false,
                },
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: "white", // Text color
                        anchor: "center", // Positioning
                        align: "center",
                        font: {
                            size: 11, // Font size
                            weight: "bold",
                        },
                        formatter: (value) => value, // Show the value inside the chart
                    },
                },
            },
        });

        return () => {
            // Destroy the chart when the component unmounts
            chartInstanceRef.current.destroy();
        };
    }, []); // Run only on component mount

    useEffect(() => {
        // Update the chart when `chartData` changes
        if (chartInstanceRef.current) {
            chartInstanceRef.current.data.labels = chartData.labels;
            const formatedData = chartData['data'].map(num => parseFloat(parseFloat(num).toFixed(2)))
            //chartInstanceRef.current.data.datasets[0].data = chartData.data;
            chartInstanceRef.current.data.datasets[0].data = formatedData;
            chartInstanceRef.current.update();
        }
    }, [chartData]);

    return <canvas ref={chartRef} className="transaction-chart"></canvas>;
};

export default DoughnutChart;