import React from 'react'

function PointScreen({ point }) {
    return (
        <div className="section1_knowlege psd4s1_bg">
            <div className="container">
                <div className="s1_inner  psd4_inner_section">
                    <div className="s1_logo fom">
                        <div className="left">
                            <div className="content">
                                <a href="#"><span><img src="../../assets/images/front/images/Quiz aarow Icon.png" /></span>Quiz</a>
                            </div>

                        </div>
                        <div className="center">
                            <div className="images">

                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src="../../assets/images/front/images/home1.png" />
                            </div>
                        </div>
                    </div>
                    <div className="s1_form_box boxes psd4_border psd-6bor psd_7_innner_main">
                        <div className="s1_fox_bg_img  psd44">
                            <img src="../../assets/images/front/images/home2.png" alt="img" />
                        </div>
                        <div className="psd4_padding">
                            <div className="psd-7-left">
                                <div className="image7-sec">
                                    <div className="heading-psd7">
                                        <h2>{point} POINTS</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="psd-7-center">
                                <div className="image8">
                                    {point === -10 ? <img src="../../assets/images/front/images/wrong-answer.png" /> : <img src="../../assets/images/front/images/Layer 45.png" />}
                                </div>
                            </div>


                        </div>
                        {/* <div className="psd8-right">
                            <div className="button-psdd">
                                <a href="#">NEXT</a>
                            </div>
                        </div> */}
                        <div className="psd_bg_images psd-8right psd77"><img src="../../assets/images/front/images/gee.png" alt="img" /></div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PointScreen