import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DisableBackButton = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handlePopState = () => {
            navigate(1); // Moves forward to prevent going back
        };

        // Push a new history state
        window.history.pushState(null, "", window.location.href);
        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [navigate]);

    return null;
};

export default DisableBackButton;