import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { setZOne } from '../../store/Slice/userSlice';
import UserFooterMenu from '../../components/UserFooterMenu';
import { useDispatch } from 'react-redux';
import DisableBackButton from "../../DisableBackButton"
function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleTeamProgress = () => {
        dispatch(setZOne({ zone: "" }));
        navigate("/team-progress");
    }
    const handleZoneProgress = () => {
        navigate("/zone-progress");
    }

    const handleOverAllProgress = () => {
        dispatch(setZOne({ zone: "Overl All" }));
        navigate("/over-all/team-progress");
    }

    return (
        <div className="section1_knowlege psd4s1_bg">
            <div className="container">
                <div className="s1_inner  psd4_inner_section">
                    <div className="s1_logo fom">
                        <div className="left">
                            <div className=" ps9_respnsive">
                                <a href="#"><span><img src="../../assets/images/front/images/Quiz aarow Icon.png" /></span>Leadership</a>
                            </div>

                        </div>
                        <div className="center">
                            <div className="images">

                            </div>
                        </div>
                        <div className="right">
                            <div className="image s9_main_logo_psd9">
                                <img src="../../assets/images/front/images/home1.png" />
                            </div>
                        </div>
                    </div>
                    <div className="s1_form_box boxes psd4_border psd-6bor psd_7_innner_main">
                        <div className="s1_fox_bg_img  psd44">
                            <img src="../../assets/images/front/images/home2.png" alt="img" />
                        </div>
                        <div className="boxespsd9">
                            <div className="box1-9">
                                <div className="left-box1">
                                    <div className="image-psd9">
                                        <img src="../../assets/images/front/images/psd9line.png" />

                                    </div>
                                    <div className="dot-9">

                                    </div>
                                </div>
                                <div className="center-psd-9" onClick={handleTeamProgress}>
                                    <div className="bothsec-9">
                                        <div className="team-iamge9">
                                            <img src="../../assets/images/front/images/Icone 1 Team.png" />
                                        </div>
                                        <div className="heading-psd9">
                                            <h2>TEAM</h2>
                                        </div>

                                    </div>
                                </div>
                                <div className="right-psd-9">
                                    <div className="image-psd-9">
                                        <img src="../../assets/images/front/images/psd9lines3.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="boxespsd9">
                            <div className="box1-9  B_2ND">
                                <div className="left-box1">
                                    <div className="image-psd9 psd9_bluee">
                                        <img src="../../assets/images/front/images/psd9line.png" />

                                    </div>
                                    <div className="dot-9">

                                    </div>
                                </div>
                                <div className="center-psd-9" onClick={handleZoneProgress}>
                                    <div className="bothsec-9 psd9_boxs">
                                        <div className="team-iamge9">
                                            <img src="../../assets/images/front/images/Icone 2 Zone.png" />
                                        </div>
                                        <div className="heading-psd9">
                                            <h2>ZONE</h2>
                                        </div>

                                    </div>
                                </div>
                                <div className="right-psd-9">
                                    <div className="psd2ss line_side">
                                        <img src="../../assets/images/front/images/psd9lines3.png" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="boxespsd9">
                            <div className="box1-9">
                                <div className="left-box1">
                                    <div className="image-psd9">
                                        <img src="../../assets/images/front/images/psd9line.png" />

                                    </div>
                                    <div className="dot-9">

                                    </div>
                                </div>
                                <div className="center-psd-9" onClick={handleOverAllProgress}>
                                    <div className="bothsec-9">
                                        <div className="team-iamge9">
                                            <img src="../../assets/images/front/images/Icone 3 Overall.png" />
                                        </div>
                                        <div className="heading-psd9">
                                            <h2>OVERALL</h2>
                                        </div>

                                    </div>
                                </div>
                                <div className="right-psd-9">
                                    <div className="image-psd-9">
                                        <img src="../../assets/images/front/images/psd9lines3.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <UserFooterMenu classname="s1_psd2_dflex psd8_S8 s9_ps9_bottom_s9" />
                        {/* <div className="s1_psd2_dflex psd8_S8 s9_ps9_bottom_s9">
                            <div className="s1_psd2_1">
                                <Link to="/home"><img
                                    src="../../assets/images/front/images/Home Icon.png"
                                    alt="images" />
                                </Link>
                            </div>
                            <div className="s1_psd2_2">
                                <a href="#"><img src="../../assets/images/front/images/Star.png" alt="img" /></a>
                            </div>
                            <div className="s1_psd2_3">
                                <a href="#"><img src="../../assets/images/front/images/Contact Icon.png" alt="img" /></a>
                            </div>
                        </div> */}
                        <div className="psd_bg_images psd-8right psd9_bottom_img">
                            <img src="../../assets/images/front/images/gee.png" alt="img" /></div>

                    </div>
                </div>
            </div>
            <DisableBackButton />
        </div>
    )
}

export default Home