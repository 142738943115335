import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { quizQuestionByCID } from '../../API/api'; // Import the specific API function
import { useDispatch, useSelector } from 'react-redux';
import { setQuestions } from '../../store/Slice/quizSlice';
import Loaders from './Loaders';
import UserFooterMenu from '../../components/UserFooterMenu';
import DisableBackButton from "../../DisableBackButton"


function Welcome() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user); // Access the user object
    const navigate = useNavigate();

    useEffect(() => {
        async function callQuizQuestionAPI(cID) {
            setLoading(true);
            const quizQuestionList = await quizQuestionByCID(user.token, cID);
            setLoading(false);

            if (quizQuestionList.status === 200) {
                if (quizQuestionList["data"].data.length) {
                    dispatch(setQuestions(quizQuestionList.data));
                } else {
                    navigate("/home");
                }

            }
        }
        callQuizQuestionAPI(user.user.cID);
    }, []);


    return (
        <div className="section1_knowlege">
            {loading && <Loaders />}
            <div className="container">
                <div className="s1_inner">
                    <div className="s1_logo">
                        <a href="#"> <img src="../../assets/images/front/images/home1.png" alt="images" /></a>
                    </div>
                    <div className="s1_form_box">
                        <div className="s1_fox_bg_img">
                            <img src="../../assets/images/front/images/home2.png" alt="img" />
                        </div>

                        <div className="psd3_main">
                            <div className="s1_psd_heading">
                                <h2>Welcome {user.user.name}</h2>
                            </div>
                            <div className="s1_psd_paragraph">
                                <p>your Quiz of the day is ready dive
                                    in a score your points and
                                    for your team</p>
                            </div>
                            <div className="s1_psd2_playbtn">
                                <Link to="/quiz"><img src="../../assets/images/front/images/Play Now logo.png" alt="img" /></Link>
                            </div>
                            <UserFooterMenu classname="s1_psd2_dflex " />
                            {/* <div className="s1_psd2_dflex">
                                <div className="s1_psd2_1">
                                    <Link to="/home"><img src="../../assets/images/front/images/Home Icon.png" alt="images" /></Link>
                                </div>
                                <div className="s1_psd2_2">
                                    <a href="#"><img src="../../assets/images/front/images/Star.png" alt="img" /></a>
                                </div>
                                <div className="s1_psd2_3">
                                    <a href="#"><img src="../../assets/images/front/images/Contact Icon.png" alt="img" /></a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="fome_carachter_icon">
                    <img src="../../assets/images/front/images/c2.png" alt="img" />

                </div>
                <div className="fome_carachter_icon1">
                    <img src="../../assets/images/front/images/blueline.png" alt="img" />
                </div>
            </div>
            <DisableBackButton />
        </div>

    )
}

// Component to Disable Browser Back Button


export default Welcome