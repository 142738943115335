import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from '../../store/Slice/userSlice';
import { login } from '../../API/api'; // Import the specific API function
import { loadScript } from '../../utils/loadScript';

const AdminLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize the navigate function
    const [apierror, setApiErro] = useState();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    })
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Clear error message when user starts typing
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: "",
            });
        }
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.email) formErrors.email = "Email is required";
        if (!formData.password) formErrors.password = "Password is required";
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            let loginData = await login(formData);

            if (loginData.status === 200) {
                let userData = loginData.data;
                setFormData({
                    name: "",
                    email: "",
                    password: "",
                });
                setApiErro("");
                dispatch(setUser(userData));
                navigate("/admin-panel/dashboard");
            } else {
                setApiErro(loginData.data.message)
            }
        }
    };

    useEffect(() => {
        // Remove the first CSS file
        const firstCSS = document.querySelector('link[href="../../assets/css/front/style.css"]');
        if (firstCSS) {
            firstCSS.parentNode.removeChild(firstCSS);
        }

        // Add the second CSS file
        const secondCSS = document.createElement("link");
        secondCSS.rel = "stylesheet";
        secondCSS.href = "../../assets/css/style.css";
        document.head.appendChild(secondCSS);

        // Cleanup: Remove the second CSS file when the component unmounts
        // return () => {
        //     if (secondCSS.parentNode) {
        //         secondCSS.parentNode.removeChild(secondCSS);
        //     }
        // };
    }, []);


    return <div className="container-scroller" >
        <div className="container-fluid page-body-wrapper full-page-wrapper" >
            <div className="row w-100" >
                <div className="content-wrapper full-page-wrapper d-flex align-items-center auth login-bg" >
                    <div className="card col-lg-4 mx-auto" >
                        <div className="card-body px-5 py-5" >
                            <h3 className="card-title text-start mb-3" > Login</h3 >
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Username or email *</label>
                                    <input
                                        type="email"
                                        className="form-control p_input"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                                </div>
                                <div className="form-group" >
                                    <label>Password *</label>
                                    <input
                                        type="password"
                                        className="form-control p_input"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    {errors.password && <span style={{ color: "red" }}>{errors.password}</span>}
                                </div >
                                <div className="form-group d-flex align-items-center justify-content-between" style={{ color: "red" }} >
                                    {apierror}
                                </div >
                                <div className="form-group d-flex align-items-center justify-content-between" >
                                    {/* <div className="form-check" >
                                        <label className="form-check-label" >
                                            <input type="checkbox" className="form-check-input" /> Remember me </label >
                                    </div > */}
                                    {/* <a href="#" className="forgot-pass" > Forgot password</a > */}
                                </div >
                                <div className="text-center d-grid gap-2" >
                                    <button type="submit" className="btn btn-primary btn-block enter-btn" > Login</button >
                                </div >
                                {/* <div className="d-flex" >
                                    <button className="btn btn-facebook me-2 col" >
                                        <i className="mdi mdi-facebook" ></i > Facebook </button >
                                    <button className="btn btn-google col" >
                                        <i className="mdi mdi-google-plus" ></i > Google plus </button >
                                </div > */}
                                {/* <p className="sign-up" > Don't have an Account?<a href="#"> Sign Up</a></p> */}
                            </form >
                        </div >
                    </div >
                </div >
            </div >
        </div >
    </div >;
};

export default AdminLogin;