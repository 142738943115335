import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllClient, getTeamDataByClientID, reptoggleStatus, deleteRep } from '../../API/api'; // Import the specific API function
import { useSelector, useDispatch } from "react-redux";
function Team() {
    const [apiMsg, setApiMSG] = useState();
    const user = useSelector((state) => state.user.user); // Access the user object
    const [options, setOptions] = useState([{ "_id": "", "name": "Please select Client" }]);
    const [client, setClient] = useState()
    const [errors, setErrors] = useState({});
    const [teamList, setTeamList] = useState([]);

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setClient(value)

    };
    useEffect(() => {
        fetchTeamData();
    }, [client]);
    const fetchTeamData = async () => {
        const teamData = await getTeamDataByClientID(user.token, client);
        if (teamData.status === 200) {
            setTeamList(teamData.data.data);
        }
    };

    useEffect(() => {
        const loadDropdownData = async () => {
            try {
                const clientList = await getAllClient(user.token);

                if (clientList.status === 200) {
                    let cData = clientList.data.data;
                    let finalData = cData.filter(function (c) { return c.status === "Active" })

                    setOptions(finalData);
                } else {
                    setOptions([])
                }
            } catch (err) {
                console.error(err);
            }
        };
        loadDropdownData();
    }, []);

    const statusHandler = async (id) => {
        let clientRes = await reptoggleStatus(user.token, id);
        if (clientRes.status === 200) {
            setApiMSG(clientRes.data.message);
        } else {
            setApiMSG(clientRes.data.message)
        }
        fetchTeamData();
    }

    const deleteRepHandler = async (id) => {
        let clientRes = await deleteRep(user.token, id);
        if (clientRes.status === 200) {
            setApiMSG(clientRes.data.message);
        } else {
            setApiMSG(clientRes.data.message)
        }
        fetchTeamData();
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title"> Team </h3>
                <h3 className="page-title text-success"> {apiMsg}  </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin-panel/team/add" >Add</Link></li>
                        {/* <li className="breadcrumb-item active" aria-current="page">Form elements</li> */}
                    </ol>
                </nav>
            </div>
            <div className="form-group">
                <label htmlFor="exampleInputUsername1">Client</label>
                <select className="form-select" id="exampleSelectGender" name="cID" value={client} onChange={handleChange}>
                    <option value="">Please select client</option>
                    {options.map((c, index) => (
                        <option key={index} value={c._id}>{c.name}</option>
                    ))}
                </select>

            </div>
            <div className="row ">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            {/* <h4 className="card-title">Order Status</h4> */}
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>

                                            <th> SM Name </th>
                                            <th> SM Phone </th>
                                            <th> SM Emp. code </th>
                                            <th> Rep Name </th>
                                            <th> Rep Phone </th>
                                            <th> Rep Emp. code </th>
                                            <th>  </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {teamList.map((c) => (
                                            <tr key={c._id}>
                                                <td >{c.salesManagerName}</td>
                                                <td >{c.salesManagerPhone}</td>
                                                <td >{c.salesManagerempcode}</td>
                                                <td >{c.repName}</td>
                                                <td >{c.repPhone}</td>
                                                <td> {c.repempcode} </td>

                                                <td>
                                                    {/* <button type="button" onClick={() => editHandler(c.repId)} className="btn btn-rounded btn-icon btn-info"   >
                                                        <i className="fa fa-pencil"></i>
                                                    </button> */}
                                                    <button type="button" onClick={() => statusHandler(c.repId)} className={c.repStatus === "Active" ? "btn btn-rounded btn-icon btn-success" : "btn btn-rounded btn-icon btn-danger"}   >
                                                        <i className={c.repStatus === "Active" ? "fa fa-unlock" : "fa fa-lock"}></i>
                                                    </button>

                                                    <button type="button" onClick={() => { deleteRepHandler(c.repId) }} className="btn btn-inverse-primary btn-rounded btn-icon">
                                                        <i className="fa fa-trash-o"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team