import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getZoneData } from '../../API/api'; // Import the specific API function
import { useDispatch, useSelector } from 'react-redux';
import { setZOne } from '../../store/Slice/userSlice';
import UserFooterMenu from '../../components/UserFooterMenu';
function ZoneProgress() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [zoneOption, setZoneOption] = useState([]);
    const user = useSelector((state) => state.user.user); // Access the user object
    useEffect(() => {
        const getZoneDataFN = async () => {
            let token = user.token;
            let cID = user.user.cID;

            let zoneResult = await getZoneData(token, cID)



            if (zoneResult.status === 200) {
                if (zoneResult["data"]['data'].length) {
                    setZoneOption(zoneResult["data"]["data"])
                } else {
                    setZoneOption([]);
                }
            }
        };
        getZoneDataFN();
    }, []);

    const handlerClick = (zone) => {
        dispatch(setZOne({ zone }));
        navigate("/zone/team-progress")
    }

    return (
        <div className="section1_knowlege psd4s1_bg">
            <div className="container">
                <div className="s1_inner  psd4_inner_section">
                    <div className="s1_logo fom">
                        <div className="left">
                            <div className="content">
                                <a href="#"><span><img src="../../assets/images/front/images/Quiz aarow Icon.png" /></span>Zone</a>
                            </div>

                        </div>
                        <div className="center991">
                            <div className="images psd11_loc">
                                <img src="../../assets/images/front/images/psd11loc.png" />
                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src="../../assets/images/front/images/home1.png" />
                            </div>
                        </div>
                    </div>
                    <div className="s1_form_box boxes psd4_border psd-6bor">
                        <div className="s1_fox_bg_img  psd44">
                            <img src="../../assets/images/front/images/home2.png" alt="img" />
                        </div>
                        <div className="divvvvvv"></div>

                        <div className="psd10_lines">
                            {zoneOption.map((r, index) => (
                                <div key={index} onClick={() => handlerClick(r.zone)} className={index === 0 ? "psd11_im11 bx1_psd" : "psd11_im11 psd11_2nd"}>
                                    <span><img src={`../../assets/images/front/images/${index === 0 ? "location.svg" : "blue location.svg"}`} alt="img" /></span><h2>{r.zone} Zone</h2>
                                </div>
                            ))}

                            <UserFooterMenu classname="s1_psd2_dflex psd8_S8 s9_ps9_bottom_s9 " />
                            {/* <div className="s1_psd2_dflex psd8_S8 s9_ps9_bottom_s9">
                                <div className="s1_psd2_1">
                                    <Link to="/home"><img src="../../assets/images/front/images/Home Icon.png" alt="images" /></Link>
                                </div>
                                <div className="s1_psd2_2">
                                    <a href="#"><img src="../../assets/images/front/images/Star.png" alt="img" /></a>
                                </div>
                                <div className="s1_psd2_3">
                                    <a href="#"><img src="../../assets/images/front/images/Contact Icon.png" alt="img" /></a>
                                </div>
                            </div> */}

                        </div>
                        <div className="psd_bg_images psd10_bg_999">
                            <img src="../../assets/images/front/images/gee.png" alt="img" /></div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default ZoneProgress