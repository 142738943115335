import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toZonedTime } from "date-fns-tz";
import { Link } from 'react-router-dom';
import UserFooterMenu from '../../components/UserFooterMenu';
import DisableBackButton from "../../DisableBackButton"
function TodayQuizCompleted() {
    const userData = useSelector((state) => state.user.user); // Access the user object


    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState("");

    useEffect(() => {
        const calculateNextQuizTime = () => {
            // Current Date in IST
            const now = new Date();
            const timeZone = "Asia/Kolkata";
            const nowIST = toZonedTime(now, timeZone);

            // Next Quiz Time: 8:00 AM IST tomorrow
            const nextQuizTime = new Date(
                nowIST.getFullYear(),
                nowIST.getMonth(),
                nowIST.getDate() + 1,
                8, // 8:00 AM
                0,
                0
            );

            return nextQuizTime;
        };

        const updateTimer = () => {
            const now = new Date();
            const timeZone = "Asia/Kolkata";
            const nowIST = toZonedTime(now, timeZone);
            const nextQuizTime = calculateNextQuizTime();

            const timeDiff = nextQuizTime - nowIST; // Difference in milliseconds

            if (timeDiff > 0) {
                const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
                const seconds = Math.floor((timeDiff / 1000) % 60);

                setHours(hours);
                setMinutes(minutes)
                setTimeRemaining(
                    `${hours} hours ${minutes} minutes ${seconds} seconds`
                );
            } else {
                setHours(0);
                setMinutes(0)
                setTimeRemaining("Quiz is now available!");
            }
        };

        // Start Timer
        const interval = setInterval(updateTimer, 1000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <div className="section1_knowlege">
            <div className="container">
                <div className="s1_inner">
                    <div className="s1_logo">
                        <a href="#"> <img src="../../assets/images/front/images/home1.png" alt="images" /></a>
                    </div>
                    <div className="s1_form_box">
                        <div className="s1_fox_bg_img">
                            <img src="../../assets/images/front/images/home2.png" alt="img" />
                        </div>

                        <div className="psd3_main">
                            <div className="s1_psd_heading s1_psd">
                                <h2>Welcome {userData.user.name}</h2>
                            </div>
                            <div className="s1_psd_paragraph s1_psd_p">
                                <p>you have completed the Quiz of<br />
                                    the day the next Quiz Starts in</p>
                            </div>
                            <div className="timer">
                                <div className="countdown">
                                    <div>
                                        <div className="c1_down">
                                            <div className="time-box">0</div>
                                            <div className="time-box">0</div>
                                        </div>
                                        <div className="label">DAYS</div>
                                    </div>
                                    <div className="DOTS">:</div>
                                    <div>
                                        <div className="c1_down">
                                            <div className="time-box">{(hours >= 10 && hours <= 99) ? parseInt(hours.toString()[0]) : 0}</div>
                                            <div className="time-box">{(hours >= 10 && hours <= 99) ? parseInt(hours.toString()[1]) : 0}</div>
                                        </div>
                                        <div className="label">HOURS</div>
                                    </div>
                                    <div className="DOTS">:</div>
                                    <div>
                                        <div className="c1_down">
                                            <div className="time-box">{(minutes >= 10 && minutes <= 99) ? parseInt(minutes.toString()[0]) : 0}</div>
                                            <div className="time-box">{(minutes >= 10 && minutes <= 99) ? parseInt(minutes.toString()[1]) : 0}</div>
                                        </div>
                                        <div className="label">MINUTES</div>
                                    </div>
                                </div>
                            </div>
                            <UserFooterMenu classname="s1_psd2_dflex " />
                            {/* <div className="s1_psd2_dflex">
                                <div className="s1_psd2_1">

                                    <Link to="/home"><img
                                        src="../../assets/images/front/images/Home Icon.png"
                                        alt="images" />
                                    </Link>
                                </div>
                                <div className="s1_psd2_2">
                                    <a href="#"><img src="../../assets/images/front/images/Star.png" alt="img" /></a>
                                </div>
                                <div className="s1_psd2_3">
                                    <a href="#"><img src="../../assets/images/front/images/Contact Icon.png" alt="img" /></a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="fome_carachter_icon">
                    <img src="../../assets/images/front/images/Chrecter.png" alt="img" />

                </div>
                <div className="fome_carachter_icon1">
                    <img src="../../assets/images/front/images/blueline.png" alt="img" />
                </div>
            </div>
            <DisableBackButton />
        </div>
    )
}

export default TodayQuizCompleted