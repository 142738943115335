import React, { useEffect, useState } from 'react'
import { setZOne } from "../store/Slice/userSlice";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';

import { getAllSalesManagerByCID } from '../API/api'; // Import the specific API function
import SidebarMenu from './SidebarMenu';

function Sidebar({ userRole }) {
    const dispatch = useDispatch();
    const [menuItems, setMenuItems] = useState([]);
    const user = useSelector((state) => state.user.user); // Access the user object
    const sidebarclass = useSelector((state) => state.user.sidebarClass); // Access the user object

    useEffect(() => {

        const getMenuItems = async () => {
            let returnData = [];
            if (userRole === "Admin") {
                returnData = [
                    { name: "Dashboard", path: "/admin-panel/dashboard" },
                    { name: "Client", path: "/admin-panel/client" },
                    { name: "M-Manager", path: "/admin-panel/m-manager" },
                    { name: "Team", path: "/admin-panel/team" },
                    { name: "Sales Manager", path: "/admin-panel/sales-manager" },
                    { name: "Rep", path: "/admin-panel/rep" },
                    { name: "Quiz", path: "/admin-panel/quiz" },
                    // { name: "Setting", path: "/admin-panel/setting" }
                ];
                let obj = {
                    "name": "Log out",
                    "path": `/logout`
                }
                returnData.push(obj);
            } else if (userRole === "M-Manager") {

                let salesManagerData = await getAllSalesManagerByCID(user.token, user.user.cID)
                if (salesManagerData.status === 200) {
                    let zoneData = salesManagerData.data.data;
                    if (zoneData.length > 0) {
                        dispatch(setZOne({ zone: zoneData[0]["zone"] }));
                    }
                    returnData.push({
                        "name": 'Dashboard',
                        "path": `/manager/dashboard`
                    });
                    for (let z in zoneData) {
                        let obj = {
                            "name": zoneData[z]['zone'],
                            "path": `/manager/zone/${zoneData[z]['zone'].trim()}`
                        }
                        returnData.push(obj);
                    }
                    let obj = {
                        "name": "Log out",
                        "path": `/logout`
                    }
                    returnData.push(obj);
                }
            } else if (userRole === "S-Manager") {
                let obj = {
                    "name": "Log out",
                    "path": `/logout`
                }
                returnData.push(obj);
            } else {
                returnData = [{ name: "Login", path: "/login" }];
            }
            setMenuItems(returnData);
        };
        getMenuItems()
    }, [userRole]);

    return (
        <nav className={`sidebar sidebar-offcanvas ${sidebarclass}`} id="sidebar">
            <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                <a className="sidebar-brand brand-logo" href="index.html"><img src="../../assets/images/kpl-sidebar.png" alt="logo" /></a>
                <a className="sidebar-brand brand-logo-mini" href="index.html"><img src="../../assets/images/kpl-sidebar.png" alt="logo" /></a>
            </div>
            <ul className="nav">
                <li className="nav-item profile">
                    <div className="profile-desc">
                        <div className="profile-pic">
                            <div className="count-indicator">
                                {/* <img className="img-xs rounded-circle " src="../../assets/images/faces/face15.jpg" alt="" /> */}
                                <span className="count bg-success"></span>
                            </div>
                            <div className="profile-name">
                                <h5 className="mb-0 font-weight-normal">{user.user.name}</h5>
                                <span>{user.user.role}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <SidebarMenu menu={menuItems} />
            </ul>
        </nav>
    )
}

export default Sidebar