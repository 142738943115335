import React, { useEffect, useState } from 'react'
import { getUserRankByOption, getUserZoneRankByOption } from '../../API/api'; // Import the specific API function
import { useSelector } from 'react-redux';
import Loaders from './Loaders';
import { Link, } from 'react-router-dom';
import UserFooterMenu from '../../components/UserFooterMenu';
import DisableBackButton from "../../DisableBackButton"

function TeamProgress() {

    const zone = useSelector((state) => state.user.zone); // Access the user object

    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.user.user); // Access the user object
    const [rankOption, setRankOption] = useState([]);

    const [activeIndex, setActiveIndex] = useState(1);

    const filterOption = {
        "1": "today",
        "2": "7days",
        "3": "overall"
    }
    // Function to handle click and set the active index
    const handleClick = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        const getRank = async () => {
            let token = user.token;
            let cID = user.user.cID;
            setLoading(true);
            let userRankResult = "";
            if (zone !== "" && zone !== "Overl All") {
                userRankResult = await getUserZoneRankByOption(token, zone, cID, filterOption[activeIndex])
            } else {
                userRankResult = await getUserRankByOption(token, cID, filterOption[activeIndex])
            }

            setLoading(false);
            if (userRankResult.status === 200) {
                if (userRankResult["data"]['data'].length) {
                    setRankOption(userRankResult["data"]["data"])
                } else {
                    setRankOption([]);
                }
            }
        };
        getRank();
    }, [activeIndex]);


    const getRankClass4 = (rank) => {
        if (rank > 3) {
            return `../../assets/images/front/images/admin.svg`
        } else {
            return `../../assets/images/front/images/tropi.png`
        }
    }

    const getOrdinalSuffix = (rank) => {
        if (typeof rank !== "number" || rank <= 0) {
            throw new Error("Rank must be a positive integer");
        }

        const lastDigit = rank % 10;
        const lastTwoDigits = rank % 100;

        // Check for special cases: 11th, 12th, 13th
        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return `th`;
        }

        // Determine suffix based on the last digit
        switch (lastDigit) {
            case 1:
                return `st`;
            case 2:
                return `nd`;
            case 3:
                return `rd`;
            default:
                return `th`;
        }
    }

    return (
        <div className="section1_knowlege psd4s1_bg">
            {loading && <Loaders />}
            <div className="container">
                <div className="s1_inner  psd4_inner_section">
                    <div className="s1_logo fom">
                        <div className="left">
                            <div className="content psd12_left_main  overallarrow">
                                <a href="#"><span><img src="../../assets/images/front/images/Quiz aarow Icon.png" alt="img" /></span>Overall</a>
                            </div>

                        </div>
                        <div className="center9922">
                            <div className="overallimg">
                                <img src="../../assets/images/front/images/psd12overall.png" alt="img" />
                            </div>
                        </div>
                        <div className="right">
                            <div className="image psd10_topimg">
                                <img src="../../assets/images/front/images/home1.png" alt="img" />
                            </div>
                        </div>
                    </div>
                    <div className="s1_form_box boxes psd4_border psd-6bor psd100s">
                        <div className="s1_fox_bg_img  psd44">
                            <img src="../../assets/images/front/images/home2.png" alt="img" />
                        </div>
                        <div className="divvvvvv"></div>
                        <div className="psd10">
                            <div className="psd10_inner">
                                <div
                                    className={activeIndex === 1 ? 'psd10_1' : 'psd10_2'}
                                    onClick={() => handleClick(1)}
                                >
                                    {/* <label>Today</label> */}
                                    <a >Today</a>
                                </div>
                                <div className={activeIndex === 2 ? 'psd10_1' : 'psd10_2'}
                                    onClick={() => handleClick(2)}
                                >
                                    {/* <label>Last 7 Days</label> */}
                                    <a > Last 7 Days </a>
                                </div>
                                <div className={activeIndex === 3 ? 'psd10_1' : 'psd10_2'}
                                    onClick={() => handleClick(3)}
                                >
                                    {/* <label>All Time</label> */}
                                    <a >All Time</a>
                                </div>
                            </div>
                        </div>
                        {rankOption.map((r, index) => (
                            <div className="psd10_lines" key={index} aa={index}>
                                <div className={`ps1_lines1_bg ${index >= 3 ? "ps1_bg5" : "ps1_bg" + (index + 2)}`}>
                                    <div className={`psd1_left_win ${index >= 3 ? "ps1_win_1 psd1000" : ""}`}>
                                        <h1>{r.rank}
                                            <sup>{getOrdinalSuffix(r.rank)}</sup></h1>
                                    </div>
                                    <div className={`psd2_main_player ${index >= 3 ? "psd_admin" : ""}`}>
                                        <h2>{r.repDetails[0].name}</h2>
                                    </div>
                                    <div className="psd3_main_tropi">
                                        <img src={getRankClass4(r.rank)} alt="img" />
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                        <UserFooterMenu classname="s1_psd2_dflex psd8_S8 s9_ps9_bottom_s9 list-record" />
                        {/* <div className="s1_psd2_dflex psd8_S8 s9_ps9_bottom_s9 list-record">
                            <div className="s1_psd2_1">
                                <Link to="/home"><img src="../../assets/images/front/images/Home Icon.png" alt="images" /></Link>
                            </div>
                            <div className="s1_psd2_2">
                                <a href="#"><img src="../../assets/images/front/images/Star.png" alt="img" /></a>
                            </div>
                            <div className="s1_psd2_3">
                                <a href="#"><img src="../../assets/images/front/images/Contact Icon.png" alt="img" /></a>
                            </div>
                        </div> */}
                        <div></div>

                        <div className="psd_bg_images psd10_bg_999"><img src="../../assets/images/front/images/gee.png" alt="img" /></div>
                    </div>

                </div>
            </div>
            <DisableBackButton />
        </div>
    )
}

export default TeamProgress