import axios from 'axios';

export const timeout = async () => {
    setTimeout(() => {
        return true;
    }, 5000);
};

export const mmanagertoggleStatus = async (token, id) => {
    try {
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/mmanager/toggle-status/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const salestoggleStatus = async (token, id) => {
    try {
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/salesmanager/toggle-status/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const quiztoggleStatus = async (token, id) => {
    try {
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/quiz/toggle-status/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};
export const reptoggleStatus = async (token, id) => {
    try {
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/rep/toggle-status/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const toggleStatus = async (token, id) => {
    try {
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/client/toggle-status/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};


export const deleteQuizQuestion = async (token, id) => {
    try {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/quiz/delete/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};
export const deleteClient = async (token, id) => {
    try {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/client/delete/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const deleteSalesManager = async (token, id) => {
    try {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/salesmanager/delete/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const deleteRep = async (token, id) => {
    try {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/rep/delete/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const deleteMManager = async (token, id) => {
    try {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/mmanager/delete/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};
export const getAllMManager = async (token) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: '/mmanager/list',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};



export const getAllSalesManager = async (token) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: '/salesmanager/list',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const getAllRep = async (token) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: '/rep/list',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const getAllClient = async (token) => {

    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: '/client/list',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};



export const getQuizQuestionByClientID = async (token, cID) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/quiz/question-list/${cID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const addQizData = async (token, formData) => {
    try {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: '/quiz/excel-data',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            data: formData
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const getTeamDataByClientID = async (token, cID) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/team/find-team/${cID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const addTeamData = async (token, formData) => {
    try {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: '/team/excel-data',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            data: formData
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const addRep = async (token, formData, id) => {
    try {
        let method = "POST";
        let url = "/rep/add";
        if (id) {
            method = "PUT";
            url = `/rep/add/${id}`;
        }
        let config = {
            method: method,
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            data: formData
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const addSalesManager = async (token, formData, id) => {
    try {
        let method = "POST";
        let url = "/salesmanager/add";
        if (id) {
            method = "PUT";
            url = `/salesmanager/add/${id}`;
        }
        let config = {
            method: method,
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            data: formData
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const addMManager = async (token, formData, id) => {
    try {
        let method = "POST";
        let url = "/mmanager/add";
        if (id) {
            method = "PUT";
            url = `/mmanager/add/${id}`;
        }
        let config = {
            method: method,
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            data: formData
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const addClient = async (token, formData, id) => {

    let method = "POST";
    let url = "/client/add"
    if (id) {
        method = "PUT";
        url = `/client/add/${id}`
    }

    try {
        let config = {
            method,
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            data: formData
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};


export const getRepByID = async (token, id) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/rep/gebyid/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for
    }
};

export const getSalesManagerByID = async (token, id) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/salesmanager/gebyid/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for
    }
};

export const getMManagerByID = async (token, id) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/mmanager/gebyid/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for
    }
};

export const getClientByID = async (token, cID) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/client/gebyid/${cID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for
    }
};
export const getAllSalesManagerByCID = async (token, cID) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/salesmanager/getallbycid/${cID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};


export const quizQuestionByCID = async (token, cID) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/user/quiz/question/${cID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const getZoneByRepID = async (token, uID) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/user/getuserzone/${uID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;

    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const checkTodayQuizPlayByUser = async (token, uID) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/user/quiz/today-play/${uID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;

    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
}

export const getZoneData = async (token, cID) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/user/zone/${cID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;

    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
}
export const getUserZoneRankByOption = async (token, zone, cID, filterOption) => {
    let url = `/user/quiz/find-rank-zone-byoption/${cID}/${zone}/${filterOption}`;

    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;

    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
}

export const getUserRankByOption = async (token, cID, filterOption) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/user/quiz/find-rank-byoption/${cID}/${filterOption}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;

    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const getUserRank = async (token, uID, cID) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/user/quiz/find-rank/${uID}/${cID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;

    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const storeQuizResult = async (token, formData) => {
    try {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: '/user/quiz/result-store',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            data: formData
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const userLogin = async (formData) => {
    try {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: '/user/login',
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData
        };

        let data1 = await axios.request(config)
            .then((response) => {
                return response
            }).catch((error) => {
                return error.response
            });
        return data1;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};
export const login = async (formData) => {
    try {

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: '/admin/login',
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData
        };

        let data1 = await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return data1;


    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const fetchAllClient = async () => {

};