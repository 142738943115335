import axios from 'axios';

export const activeuser = async (token, cID, dateoption) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/mmanager/user-session-details/${cID}/${dateoption}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 201 || response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
}

export const zonewisePer = async (token, cID, dateoption) => {

    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/mmanager/zonewisedata/${cID}/${dateoption}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 201 || response.status === 200) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const top10reps = async (token, cID, dateoption) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/mmanager/top10rep/${cID}/${dateoption}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
};

export const top10region = async (token, cID, dateoption) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            baseURL: process.env.REACT_APP_BASE_URL,
            url: `/mmanager/top10region/${cID}/${dateoption}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let res = await axios.request(config)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    return response
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    return error.response
                }
            });
        return res;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error; // Throw the error for the caller to handle
    }
}