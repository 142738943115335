import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAllClient, addRep, getRepByID, getAllSalesManager, getAllSalesManagerByCID } from '../../API/api'; // Import the specific API function
import { useSelector } from "react-redux";

function AddRep() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [apierror, setApiErro] = useState();
    const user = useSelector((state) => state.user.user); // Access the user object
    const [options, setOptions] = useState([]);
    const [salesManageroptions, setSalesManagerOptions] = useState([]);

    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "cID") {
            const loadSalesManagerdownData = async () => {

                const salesManagerList = await getAllSalesManagerByCID(user.token, value);
                if (salesManagerList.status === 200) {
                    let cData = salesManagerList.data.data;
                    let finalData = cData.filter(function (c) { return c.status === "Active" })
                    setSalesManagerOptions(finalData);
                } else {
                    setSalesManagerOptions([])
                }
            };
            loadSalesManagerdownData();
        }

        setFormData({
            ...formData,
            [name]: value,
        });

        // Clear error message when user starts typing
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: "",
            });
        }
    };

    const validateForm = () => {
        let formErrors = {};

        if (!formData.name) formErrors.name = "Name is required";
        if (!formData.phone) formErrors.phone = "Phone is required";
        if (!formData.sID) formErrors.sID = "Sales Manager is required";
        if (!formData.empcode) formErrors.empcode = "Employee code is required";
        if (!formData.cID) formErrors.cID = "Client is required";
        return formErrors;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {

            let clientAdd = await addRep(user.token, formData, id);
            if (clientAdd.status === 200) {
                if (!id) {
                    setFormData({
                        name: "",
                        phone: "",
                        sID: "",
                        empcode: "",
                        cID: ""
                    });
                }
                setApiErro(clientAdd.data.message);
            } else {
                setApiErro(clientAdd.data.message)
            }
        }
    };

    useEffect(() => {
        const getManagerData = async () => {
            try {
                const managerData = await getRepByID(user.token, id);
                if (managerData.status === 200) {
                    let mData = managerData.data.data;
                    setFormData({
                        sID: mData.sID,
                        name: mData.name,
                        phone: mData.phone,
                        empcode: mData.empcode,
                        cID: mData.cID,
                    });
                }
            } catch (err) {
                console.error(err);
            }
        };
        getManagerData();
    }, [id]);

    useEffect(() => {
        const loadDropdownData = async () => {
            try {
                const clientList = await getAllClient(user.token);
                if (clientList.status === 200) {
                    let cData = clientList.data.data;
                    let finalData = cData.filter(function (c) { return c.status === "Active" })
                    setOptions(finalData);
                } else {
                    setOptions([])
                }
            } catch (err) {
                console.error(err);
            }

            const salesManagerList = await getAllSalesManager(user.token);

            if (salesManagerList.status === 200) {
                let cData = salesManagerList.data.data;
                let finalData = cData.filter(function (c) { return c.status === "Active" })
                setSalesManagerOptions(finalData);
            } else {
                setSalesManagerOptions([])
            }

        };
        loadDropdownData();
    }, []);

    const onClickHandler = async () => {
        navigate("/admin-panel/m-manager");
    };

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title"> {id ? "Edit " : "Add "}Rep </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin-panel/rep" >Rep</Link></li>
                        {/* <li className="breadcrumb-item active" aria-current="page">Form elements</li> */}
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            {/* <h4 className="card-title">Default form</h4> */}
                            {/* <p className="card-description"> Basic form layout </p> */}
                            <h4 className="card-title text-success">{apierror}</h4>
                            <form className="forms-sample" onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="exampleInputUsername1">Client</label>
                                    <select className="form-select" id="exampleSelectGender" name="cID" value={formData.cID} onChange={handleChange}>
                                        <option value="">Please select client</option>
                                        {options.map((c, index) => (
                                            <option key={index} value={c._id}>{c.name}</option>
                                        ))}

                                    </select>
                                    {errors.cID && <span style={{ color: "red" }}>{errors.cID}</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputUsername1">Sales Manager</label>
                                    <select className="form-select" name="sID" value={formData.sID} onChange={handleChange}>
                                        <option value="">Please select </option>
                                        {salesManageroptions.map((c, index) => (
                                            <option key={index} value={c._id}>{c.name}</option>
                                        ))}

                                    </select>
                                    {errors.sID && <span style={{ color: "red" }}>{errors.sID}</span>}
                                </div>





                                <div className="form-group">
                                    <label htmlFor="exampleInputUsername1">Name</label>
                                    <input type="text"
                                        className="form-control"
                                        id="exampleInputUsername1"
                                        placeholder="Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputUsername1">Phone no</label>
                                    <input type="text"
                                        className="form-control"
                                        id="exampleInputUsername1"
                                        placeholder="Phone No"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                    {errors.phone && <span style={{ color: "red" }}>{errors.phone}</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputUsername1">Employee Code</label>
                                    <input type="text"
                                        className="form-control"
                                        id="exampleInputUsername1"
                                        placeholder="Employee Code"
                                        name="empcode"
                                        value={formData.empcode}
                                        onChange={handleChange}
                                    />
                                    {errors.empcode && <span style={{ color: "red" }}>{errors.empcode}</span>}
                                </div>


                                <button type="submit" className="btn btn-primary me-2">{id ? "Edit" : "Add"}</button>
                                <button className="btn btn-dark" onClick={onClickHandler}>Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddRep