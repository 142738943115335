import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getUserRankByOption, getUserZoneRankByOption } from '../../API/api'; // Import the specific API function
import { Link } from 'react-router-dom';
import Loaders from './Loaders';
import UserFooterMenu from '../../components/UserFooterMenu';
function QuizComplate() {
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.user.user); // Access the user object

    const quizData = useSelector((state) => state.quiz);
    const [rank, setRank] = useState(0);
    const [zonerank, setZoneRank] = useState(0);

    useEffect(() => {
        const getUserRank = async () => {

            const [getUserRankByOptionS, getUserZoneRankByOptionS] = await Promise.all([
                getUserRankByOption(user.token, user.user.cID, "today"),
                getUserZoneRankByOption(user.token, user.zone, user.user.cID, "today")
            ]);

            if (getUserRankByOptionS?.status >= 200 && getUserRankByOptionS?.status < 300) {
                let uID = user.user._id;
                if (getUserRankByOptionS["data"]['data'].length) {
                    for (let u in getUserRankByOptionS["data"]['data']) {
                        if (getUserRankByOptionS["data"]['data'][u]["_id"] === uID) {
                            setRank(getUserRankByOptionS["data"]["data"][u]["rank"])
                        }
                    }
                }
            }

            if (getUserZoneRankByOptionS?.status >= 200 && getUserZoneRankByOptionS?.status < 300) {

                if (getUserZoneRankByOptionS["data"]['data'].length > 0) {
                    for (let u in getUserZoneRankByOptionS["data"]['data']) {
                        if (getUserZoneRankByOptionS["data"]['data'][u]["_id"] === user.user._id) {
                            setZoneRank(getUserZoneRankByOptionS["data"]["data"][u]["rank"])
                        }
                    }
                }
            }
        }
        getUserRank();
    }, []);

    return (
        <div className="section1_knowlege psd4s1_bg">
            {loading && <Loaders />}
            <div className="container">
                <div className="s1_inner  psd4_inner_section">
                    <div className="s1_logo fom">
                        <div className="left">
                        </div>
                        <div className="center">
                            <div className="images">

                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src="../../assets/images/front/images/home1.png" alt="img" />
                            </div>
                        </div>
                    </div>
                    <div className="s1_form_box boxes psd4_border psd-6bor">
                        <div className="s1_fox_bg_img  psd44">
                            <img src="../../assets/images/front/images/home2.png" alt="img" />
                        </div>
                        <div className="psd4_padding">
                            <div className="menimage">
                                <div className="men-8">
                                    <img src="../../assets/images/front/images/psddd888.png" alt="img" />
                                </div>
                                <div className="heading">
                                    <h1>{user.user.name}</h1>
                                </div>
                            </div>
                            <div className="boxespsd-8-sec">
                                <div className="headingg2  ">
                                    <h2>{quizData.correctAnswer}</h2><br />
                                    <span>Correct Answers</span>
                                </div>
                                <div className="headingg2 bg2 ">
                                    <h2>{quizData.timeTaken}</h2><br />
                                    <span>Quiz Taken</span>
                                </div>
                                <div className="headingg2 bg3 ">
                                    <h2>{zonerank}</h2><br />
                                    <span>Zone Ranking</span>
                                </div>
                                <div className="headingg2 bg4 ">
                                    <h2>{rank}</h2><br />
                                    <span>Current Ranking</span>
                                </div>
                            </div>
                            <UserFooterMenu classname="toppadin" />

                        </div>

                        <div className="hahaha">
                            <div className="psd_bg_images  pleft  ps8888"><img src="../../assets/images/front/images/gee.png" alt="img" /></div></div>
                    </div>

                </div>
            </div>


        </div>

    )
}

export default QuizComplate