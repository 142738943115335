import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { setSideBarClass } from '../store/Slice/userSlice';
import { useNavigate } from 'react-router-dom';

function Navbar() {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize the navigate function
    const user = useSelector((state) => state.user.user); // Access the user object

    const logOutHandler = () => {


        navigate("/admin-panel/login");
    }
    const handlerClick = () => {
        dispatch(setSideBarClass({ "sidebarClass": "active" }));
    }
    return (
        <nav className="navbar p-0 fixed-top d-flex flex-row">
            <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
                <a className="navbar-brand brand-logo-mini" href="index.html"><img src="../../../fav-Icon.png" alt="logo" /></a>
            </div>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
                <button className="navbar-toggler navbar-toggler align-self-center" onClick={() => handlerClick()} type="button" data-toggle="minimize">
                    <span className="mdi mdi-menu"></span>
                </button>
                {/* <ul className="navbar-nav w-100">
                    <li className="nav-item w-100">
                        <form className="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
                            <input type="text" className="form-control" placeholder="Search products" />
                        </form>
                    </li>
                </ul> */}
                <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item dropdown d-none d-lg-block">
                        {/* <a className="nav-link btn btn-success create-new-button" id="createbuttonDropdown" data-bs-toggle="dropdown" aria-expanded="false" href="#">+ Create New Project</a> */}
                        <div className="dropdown-menu dropdown-menu-end navbar-dropdown preview-list" aria-labelledby="createbuttonDropdown">
                            <h6 className="p-3 mb-0">Projects</h6>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-file-outline text-primary"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject ellipsis mb-1">Software Development</p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-web text-info"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject ellipsis mb-1">UI Development</p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-layers text-danger"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject ellipsis mb-1">Software Testing</p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <p className="p-3 mb-0 text-center">See all projects</p>
                        </div>
                    </li>



                    {/* <li className="nav-item dropdown">
                        <a className="nav-link" id="profileDropdown" href="#" data-bs-toggle="dropdown">
                            <div className="navbar-profile">
                                <img className="img-xs rounded-circle" src="../../../assets/images/faces/face15.jpg" alt="" />
                                <p className="mb-0 d-none d-sm-block navbar-profile-name">{user.user.name}</p>
                                <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end navbar-dropdown preview-list" aria-labelledby="profileDropdown">
                            <h6 className="p-3 mb-0">Profile</h6>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-cog text-success"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject mb-1">Settings</p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-logout text-danger"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content" onClick={() => logOutHandler}>
                                    <p className="preview-subject mb-1" onClick={() => logOutHandler}>Log out</p>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <p className="p-3 mb-0 text-center">Advanced settings</p>
                        </div>
                    </li> */}
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" onClick={() => handlerClick()} type="button" data-toggle="offcanvas">
                    <span className="mdi mdi-format-line-spacing"></span>
                </button>
            </div>
        </nav>
    )
}

export default Navbar