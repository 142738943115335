import { createSlice } from '@reduxjs/toolkit';
const quizSlice = createSlice({
    name: 'quiz',
    initialState: {
        questions: [], // Array to store quiz questions
        currentIndex: 0, // Track the current question index
        correctAnswer: 0,
        timeTaken: 0,
        quizcompletToday: false
    },
    reducers: {
        setQuestions: (state, action) => {
            state.questions = action.payload.data; // Set quiz questions
            state.currentIndex = 0;
        },
        updateAnswer: (state, action) => {
            const { index, selectedOption, timetaken, point } = action.payload;
            state.questions[index].selectedOption = (selectedOption === "") ? "" : state.questions[index][`option${selectedOption}`];
            state.questions[index].timetaken = timetaken;
            state.questions[index].point = point;
        },
        updateTotalTimeAndAnswer: (state, action) => {
            const { totalTime, totalCorrectAnswer } = action.payload;
            state.correctAnswer = totalCorrectAnswer
            state.timeTaken = totalTime
        },
        todayQuizComplete: (state, action) => {

            state.quizcompletToday = true
        },
        clearQuestions: (state) => {
            state.questions = []; // Clear all questions
        },

        nextQuestion: (state) => {
            if (state.currentIndex < state.questions.length) {
                state.currentIndex += 1;
            }
        },
    },
});

export const { setQuestions, updateAnswer, clearQuestions, nextQuestion, updateTotalTimeAndAnswer, todayQuizComplete } = quizSlice.actions;
export default quizSlice.reducer;