import React, { useEffect, useState } from 'react'
import { loadScript } from '../../utils/loadScript';
import { top10reps, zonewisePer, activeuser, top10region } from '../../API/m-dashboard'; // Import the specific API function
import { getAllClient } from '../../API/api'; // Import the specific API function
import { useSelector } from 'react-redux';
import DoughnutChart from "../manager/DoughnutChart";

import BarChart from '../manager/BarChart';
import LineChart from '../manager/LineChart';
import Loaders from '../user/Loaders';

function Dashboard() {
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.user.user); // Access the user object
    const [repOptions, setRepOptions] = useState([]);
    const [dateOption, setDateOption] = useState("overall")
    const [regionOptions, setRegionOptions] = useState([]);


    /* ****************************  Char start **************************** */
    const [chartData, setChartData] = useState({});
    const [correctAnswerChartData, setCorrectAnswerChartData] = useState({});
    const [inCorrectAnswerChartData, setInCorrectAnswerChartData] = useState({});
    const [clientList, setClientList] = useState([]);
    const [barData, setBarData] = useState({});
    const [cAnswerVSIncAnswer, setCAnswerVSInCAnswer] = useState({});
    const [lineData, setLineData] = useState({});
    const [cID, setCID] = useState("");

    useEffect(() => {
        const loadPageSpecificScripts = async () => {
            const firstCSS = document.querySelector('link[href="../../assets/css/front/style.css"]');
            if (firstCSS) {
                firstCSS.parentNode.removeChild(firstCSS);
            }
            // Add the second CSS file
            const secondCSS = document.createElement("link");
            secondCSS.rel = "stylesheet";
            secondCSS.href = "../../assets/css/style.css";
            document.head.appendChild(secondCSS);
            await loadScript('../../assets/js/dashboard.js');


        };
        // loadPageSpecificScripts();

        const loadDashBoard = async () => {
            setLoading(true);
            try {
                let backgroundColor = {
                    "0": ["rgba(79, 25, 36, 0.6)", "rgba(255, 99, 132, 0.6)"],
                    "1": ["rgba(39, 110, 158, 0.6)", "rgba(54, 162, 235, 0.6)"],
                    "2": ["rgba(126, 101, 36, 0.6)", "rgba(255, 206, 86, 0.6)"],
                    "3": ["rgba(30, 89, 89, 0.6)", "rgba(75, 192, 192, 0.6)"],
                    "4": ["rgba(47, 28, 85, 0.6)", "rgba(153, 102, 255, 0.6)"]
                }
                let borderColor = {
                    "0": ["rgba(255, 99, 132, 1)", "rgba(255, 99, 132, 1)"],
                    "1": ["rgba(54, 162, 235, 1)", "rgba(54, 162, 235, 1)"],
                    "2": ["rgba(255, 206, 86, 1)", "rgba(255, 206, 86, 1)"],
                    "3": ["rgba(75, 192, 192, 1)", "rgba(75, 192, 192, 1)"],
                    "4": ["rgba(153, 102, 255, 1)", "rgba(153, 102, 255, 1)"],
                }
                const [top10Res, zoneWisePer, activeUsers, top10regions] = await Promise.all([
                    top10reps(user.token, cID, dateOption),
                    zonewisePer(user.token, cID, dateOption),
                    activeuser(user.token, cID, dateOption),
                    top10region(user.token, cID, dateOption)
                ]);

                if (top10Res?.status === 200 || top10Res?.status === 201) {
                    setRepOptions(top10Res['data']['data'].slice(0, 10));
                }
                if (top10regions?.status === 200 || top10regions?.status === 201) {
                    setRegionOptions(top10regions["data"]["finalData"].slice(0, 10));
                }
                if (activeUsers?.status === 200 || activeUsers?.status === 201) {

                    let d1 = {
                        "label": "Total Reps",
                        "data": [],
                        "backgroundColor": [],
                        "borderColor": [],
                        "borderWidth": 1
                    }
                    let d2 = {
                        "label": "Active Reps",
                        "data": [],
                        "backgroundColor": [],
                        "borderColor": [],
                        "borderWidth": 1
                    }
                    let userData = activeUsers.data.data;
                    let barData = {
                        "labels": []
                    };
                    let bc = 0;
                    let bo = 0;

                    for (let ud in userData) {
                        barData["labels"].push(ud);
                        d1["data"].push(userData[ud]["totalRep"])
                        d1["backgroundColor"].push(backgroundColor[bc][0])
                        d1["borderColor"].push(borderColor[bo][0])

                        d2["data"].push(userData[ud]["activeRep"])
                        d2["backgroundColor"].push(backgroundColor[bc++][1])
                        d2["borderColor"].push(borderColor[bo++][1])
                    }

                    setBarData({ "labels": barData["labels"], "datasets": [d1, d2] })
                    setLineData({
                        "labels": barData["labels"], "datasets": [
                            { label: "Active User", fill: false, borderColor: "rgba(75, 192, 192, 1)", tension: 0.1, data: d2["data"] }
                        ]
                    })
                }
                if (zoneWisePer?.status === 200 || zoneWisePer?.status === 201) {

                    let perData = zoneWisePer.data.data;
                    let charLabels = [];
                    let chartData = [];
                    let correctAnwser = [];
                    let inCorrectAnswer = [];
                    for (let p in perData) {
                        charLabels.push(perData[p]["zone"].trim());
                        chartData.push(`${perData[p]["Percentage"]}`);
                        correctAnwser.push(perData[p]["correctAnswerPer"])
                        inCorrectAnswer.push(perData[p]["inCorrectAnswerPer"])
                    }
                    setChartData({ "labels": charLabels, data: chartData });
                    setCorrectAnswerChartData({ "labels": charLabels, data: correctAnwser });
                    setInCorrectAnswerChartData({ "labels": charLabels, data: inCorrectAnswer });

                    let barData1 = {
                        "labels": []
                    };
                    let bc = 0;
                    let bo = 0;

                    let d3 = {
                        "label": "Correct Answer",
                        "data": [],
                        "backgroundColor": [],
                        "borderColor": [],
                        "borderWidth": 1
                    }
                    let d4 = {
                        "label": "Incorrect Answer",
                        "data": [],
                        "backgroundColor": [],
                        "borderColor": [],
                        "borderWidth": 1
                    }
                    for (let ud in perData) {
                        barData1["labels"].push(perData[ud]["zone"]);
                        d3["data"].push(perData[ud]["correctAnswer"])
                        d3["backgroundColor"].push(backgroundColor[bc][0])
                        d3["borderColor"].push(borderColor[bo][0])

                        d4["data"].push(perData[ud]["inCorrectAnswer"])
                        d4["backgroundColor"].push(backgroundColor[bc++][1])
                        d4["borderColor"].push(borderColor[bo++][1])
                    }

                    setCAnswerVSInCAnswer({ "labels": barData1["labels"], "datasets": [d3, d4] })
                }
            } catch (error) {
                console.error('Error loading data:', error);
            }
            setLoading(false);
        };
        loadDashBoard();
    }, [dateOption, cID]);


    const handlerDateClick = (option) => {
        setDateOption(option.toLowerCase())
    }

    const listOfClients = async () => {
        let clientData = await getAllClient(user.token);
        setClientList(clientData.data.data);
    }
    useEffect(() => {
        const loadPageSpecificScripts = async () => {
            await loadScript('../../assets/js/dashboard.js');
        };
        loadPageSpecificScripts();
        listOfClients();
    }, []);

    const handleChange = (e) => {
        const { value } = e.target;

        if (value === "") {
            setRepOptions([])
            setRegionOptions([]);
            setChartData({});
            setCorrectAnswerChartData({});
            setInCorrectAnswerChartData({});
            setBarData({})
            setCAnswerVSInCAnswer({});
            setLineData({});
        } else {
            setCID(value);
        }
    };

    return (
        <div className="content-wrapper">
            {loading && <Loaders />}
            <div className="page-header">
                <h3 className="page-title"> Dashboard </h3>
            </div>
            <div className='row'>
                <div className="col-md-6 grid-margin stretch-card">
                    <select className="form-select" id="exampleFormControlSelect2" value={cID} onChange={handleChange}>
                        <option value="">Please select client</option>
                        {clientList.map((c, index) => (
                            <option key={index} value={c._id}>{c.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-2 col-sm-4 grid-margin stretch-card" onClick={() => handlerDateClick("overall")}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <div className="d-flex align-items-center align-self-start">
                                        <h3 style={
                                            dateOption === "overall"
                                                ? { color: "green" }
                                                : {}
                                        } className="mb-0">Over All</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-sm-4 grid-margin stretch-card" onClick={() => handlerDateClick("today")}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <div className="d-flex align-items-center align-self-start">
                                        <h3 style={
                                            dateOption === "today"
                                                ? { color: "green" }
                                                : {}
                                        } className="mb-0" >Today</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-sm-4 grid-margin stretch-card" onClick={() => handlerDateClick("weekly")}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <div className="d-flex align-items-center align-self-start">
                                        <h3 style={
                                            dateOption === "weekly"
                                                ? { color: "green" }
                                                : {}
                                        } className="mb-0">Weekly</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-sm-4 grid-margin stretch-card" onClick={() => handlerDateClick("monthly")}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <div className="d-flex align-items-center align-self-start">
                                        <h3 style={
                                            dateOption === "monthly"
                                                ? { color: "green" }
                                                : {}
                                        } className="mb-0">Monthly</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className="row">
                <div className="col-md-4 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Zone Participation</h4>
                            <div className="position-relative">
                                <div className="daoughnutchart-wrapper">

                                    {Object.keys(chartData).length !== 0 && <DoughnutChart chartData={chartData} />}
                                </div>
                                <div className="custom-value">100% <span>Total</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 grid-margin stretch-card">

                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Session Details</h4>

                            <div className="position-relative">
                                <div className="daoughnutchart-wrapper">
                                    {Object.keys(barData).length !== 0 && <BarChart data={barData} Y_axis_label={"Number of Reps"} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 grid-margin stretch-card">

                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Participation Trends</h4>

                            <div className="position-relative">
                                <div className="daoughnutchart-wrapper">
                                    {/* <LineChart data={lineData} /> */}
                                    {Object.keys(lineData).length !== 0 && <LineChart data={lineData} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row ">
                <div className="col-6 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Top 10 Reps</h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th> Zone </th>
                                            <th> Name </th>
                                            <th> Total Points </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {repOptions.map((r, index) => (
                                            <tr key={index}>
                                                <td> {r.zone} </td>
                                                <td> {r.name} </td>
                                                <td> {r.totalPoints} </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Top 10 Regions</h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th> Name </th>
                                            <th> Total Points </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {regionOptions.map((r, index) => (
                                            <tr key={index}>
                                                <td> {r.region} </td>
                                                <td> {r.points} </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Quiz Insights</h4>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Correct Answer</h4>
                                            <div className="position-relative">
                                                <div className="daoughnutchart-wrapper">

                                                    {Object.keys(correctAnswerChartData).length !== 0 && <DoughnutChart chartData={correctAnswerChartData} />}
                                                </div>
                                                <div className="custom-value">100% <span>Total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">InCorrect Answer</h4>
                                            <div className="position-relative">
                                                <div className="daoughnutchart-wrapper">

                                                    {Object.keys(inCorrectAnswerChartData).length !== 0 && <DoughnutChart chartData={inCorrectAnswerChartData} />}
                                                </div>
                                                <div className="custom-value">100% <span>Total</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">

                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Correct VS Incorrect</h4>

                                            <div className="position-relative">
                                                <div className="daoughnutchart-wrapper">
                                                    {Object.keys(cAnswerVSIncAnswer).length !== 0 && <BarChart data={cAnswerVSIncAnswer} Y_axis_label={"Number of Answer"} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Dashboard