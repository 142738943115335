import React, { useEffect, useState } from 'react'
import { loadScript } from '../../utils/loadScript';
import { getUserZoneRankByOption } from '../../API/api'; // Import the specific API function
import { useSelector } from 'react-redux';


function Zone() {
    const [selectedOption, setSelectedOption] = useState("Today");
    const label = {
        "Today": "today",
        "7 Days": "7days",
        "Overall": "overall"
    };

    const [userArray, setUserArray] = useState([]);
    let { user, zone } = useSelector((state) => state.user);

    const getUserData = async (zone, filteroption) => {
        let token = user.token;
        let cID = user.user.cID;
        let userData = await getUserZoneRankByOption(token, zone, cID, label[filteroption]);

        if (userData.status === 200) {
            setUserArray(userData.data.data)
        }
    }
    if (user.user.role === "S-Manager") {
        zone = user.user.zone;
        // getUserData(zone, selectedOption);
    }
    useEffect(() => {
        const loadPageSpecificScripts = async () => {
            const firstCSS = document.querySelector('link[href="../../assets/css/front/style.css"]');
            if (firstCSS) {
                firstCSS.parentNode.removeChild(firstCSS);
            }
            // Add the second CSS file
            const secondCSS = document.createElement("link");
            secondCSS.rel = "stylesheet";
            secondCSS.href = "../../assets/css/style.css";
            document.head.appendChild(secondCSS);
            await loadScript('../../assets/js/dashboard.js');
            if (zone !== "") {
                getUserData(zone, "Today");
            }

        };
        loadPageSpecificScripts(zone);
    }, [zone]);

    const handlerBtnClick = (type) => {
        setSelectedOption(type);
        getUserData(zone, type);
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title"> {zone} Zone </h3>
            </div>
            <div className="row">
                <div className="col-12 grid-margin">
                    <div className="template-demo">
                        <button onClick={() => handlerBtnClick("Today")} type="button" className="btn btn-primary btn-fw">Today</button>
                        <button onClick={() => handlerBtnClick("7 Days")} type="button" className="btn btn-secondary btn-fw">7 Days</button>
                        <button onClick={() => handlerBtnClick("Overall")} type="button" className="btn btn-success btn-fw">Overall</button>
                    </div>
                </div>
            </div>

            <div className="row ">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">{selectedOption} Rep List </h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th> Rep Name </th>
                                            <th> Phone </th>
                                            <th> Rank </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {userArray.map((r, index) => (
                                            <tr key={index}>
                                                <td> {r.repDetails[0].name} </td>
                                                <td> {r.repDetails[0].phone} </td>
                                                <td> {r.rank} </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Zone