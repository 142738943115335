import React from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
    const user = useSelector((state) => state.user.user); // Access the user object    
    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0 && objectName.constructor === Object;
    }
    let userObj = isObjectEmpty(user);

    if (userObj) {
        return <Navigate to="/admin-panel/login" replace />;
    }
    return children;  // Render protected component if authenticated
};

export default ProtectedRoute;