import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const UserProtectedRoute = ({ children }) => {

    useEffect(() => {
        // Remove the first CSS file
        const firstCSS = document.querySelector('link[href="../../assets/css/style.css"]');
        if (firstCSS) {
            firstCSS.parentNode.removeChild(firstCSS);
        }

        // Add the second CSS file
        const secondCSS = document.createElement("link");
        secondCSS.rel = "stylesheet";
        secondCSS.href = "../../assets/css/front/style.css";
        document.head.appendChild(secondCSS);

        // Cleanup: Remove the second CSS file when the component unmounts
        return () => {
            if (secondCSS.parentNode) {
                secondCSS.parentNode.removeChild(secondCSS);
            }
        };
    }, []);

    const user = useSelector((state) => state.user.user); // Access the user object    
    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0 && objectName.constructor === Object;
    }
    let userObj = isObjectEmpty(user);


    if (userObj) {
        return <Navigate to="/" replace />;
    }
    return children;  // Render protected component if authenticated
};

export default UserProtectedRoute;