import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto"; // Automatically registers the necessary components

const LineChart = ({ data }) => {
    // Chart data
    // const data = {
    //     labels: ["January", "February", "March", "April", "May", "June"], // X-axis labels
    //     datasets: [
    //         {
    //             label: "Active User", // Label for the dataset
    //             data: [65, 59, 80, 81, 56, 55], // Y-axis values
    //             fill: false, // No fill under the line
    //             borderColor: "rgba(75, 192, 192, 1)", // Line color
    //             tension: 0.1, // Line tension for smooth curves
    //         },
    //     ],
    // };

    // Chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true, // Show legend
                position: "top", // Legend position
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Zone", // X-axis title
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Number of Reps", // Y-axis title
                },
                beginAtZero: true, // Start the scale at zero
            },
        },
    };

    return <Line data={data} options={options} />;
};

export default LineChart;