import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from '../../store/Slice/userSlice';
import Loaders from './Loaders';
import { userLogin, checkTodayQuizPlayByUser, getZoneByRepID } from '../../API/api'; // Import the specific API function


const Login = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Initialize the navigate function
    const [apierror, setApiErro] = useState();
    const [formData, setFormData] = useState({
        phone: "",
        empcode: "",

        // M manager
        // phone: "9322815707",
        // empcode: "010101

        // Sales manager
        //phone: "9434038383",
        //empcode: "213144"
    })
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        setApiErro("");
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Clear error message when user starts typing
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: "",
            });
        }
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.phone) formErrors.phone = "phone is required";
        if (!formData.empcode) formErrors.empcode = "Employee code is required";
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            setLoading(true);
            let loginData = await userLogin(formData);
            setLoading(false);
            if (loginData.status === 200) {
                let userData = loginData.data;
                setFormData({
                    phone: "",
                    empcode: "",
                });
                setApiErro("");
                dispatch(setUser(userData));
                if (userData.user.role === "M-Manager") {
                    navigate("/manager/dashboard");
                } else if (userData.user.role === "S-Manager") {
                    navigate("/salesmanager/dashboard");
                } else {
                    let repZoneResult = await getZoneByRepID(userData.token, userData.user._id);
                    if (repZoneResult.status >= 200 && repZoneResult.status < 300) {
                        if (repZoneResult.data.data.length) {
                            let zone = repZoneResult.data.data[0]["manager_details"][0]["zone"]
                            userData = { ...userData, 'zone': zone }
                            dispatch(setUser(userData));

                        }
                    } else {
                        userData = { ...userData, 'zone': "" }
                        dispatch(setUser(userData));
                    }

                    // navigate("/quiz-complete");
                    // return false;
                    setLoading(true);
                    let todayplayResult = await checkTodayQuizPlayByUser(userData.token, userData.user._id);
                    setLoading(false);
                    if (todayplayResult.status === 200) {
                        if (todayplayResult.data.data !== null) {
                            navigate("/no-quiz");
                        } else {
                            navigate("/welcome");
                        }
                    }
                }
            } else {

                setApiErro(loginData.data.message)
            }
        }
    };


    return (

        <div className="section1_knowlege">
            {loading && <Loaders />}
            <div className="container">
                <div className="s1_inner">
                    <div className="s1_logo">
                        <a href="#"> <img src="../../assets/images/front/images/home1.png" alt="images" /></a>
                    </div>
                    <div className="s1_form_box">
                        <div className="s1_fox_bg_img">
                            <img src="../../assets/images/front/images/home2.png" alt="img" />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="login-container">

                                <label htmlFor="phone">Phone Number</label>
                                <input
                                    type="text"
                                    id="phone"
                                    placeholder="******** ********"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                {errors.phone && <span style={{ color: "red" }}>{errors.phone}</span>}
                                <div className="iconspk">
                                    <a href="#"><img src="../../assets/images/front/images/Phone.svg" alt="img" /></a>
                                </div>

                                <div className="s1_form222">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="text"
                                        id="password"
                                        placeholder="******** ********"
                                        name="empcode"
                                        value={formData.empcode}
                                        onChange={handleChange}
                                    />
                                    {errors.empcode && <span style={{ color: "red" }}>{errors.empcode}</span>}
                                    <div className="iconslk">
                                        <a href="#"><img src="../../assets/images/front/images/Lock Icon.png" alt="img" /></a>
                                    </div>
                                </div>

                                <div className="remember-container">
                                    <label style={{ color: "red" }}>{apierror}</label>

                                </div>

                                <div className="form_btn">
                                    <button type="submit">Login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="fome_carachter_icon">
                    <img src="../../assets/images/front/images/women.png" alt="img" />

                </div>
                <div className="fome_carachter_icon1">
                    <img src="../../assets/images/front/images/blueline.png" alt="img" />
                </div>
            </div>
        </div>
    );
};

export default Login;