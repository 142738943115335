import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {},
        zone: "",
        sidebarClass: ""
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setZOne: (state, action) => {
            const { zone } = action.payload;
            state.zone = zone
        },
        setSideBarClass: (state, action) => {
            state.sidebarClass = (state.sidebarClass === "") ? 'active' : ''
        }
    },

});

export const { setUser, setZOne, setSideBarClass } = userSlice.actions;

export default userSlice.reducer;