import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AdminDashboard from './Layout/AdminDashboard'

import AdminLogin from './pages/admin/AdminLogin';
import Login from './pages/user/Login';
import Welcome from './pages/user/Welcome';
import QuizStart from './pages/user/Quiz';
import QuizComplete from './pages/user/QuizComplate';
import TodayQuizCompleted from './pages/user/TodayQuizCompleted'
import Home from './pages/user/Home'
import Logout from './pages/Logout'
import TeamProgress from './pages/user/TeamProgress';
import ZoneProgress from './pages/user/ZoneProgress';
import Loaders from './pages/user/Loaders';
import Zone from './pages/manager/Zone';
import Forgotpassword from './pages/admin/Forgotpassword';
import Dashboard from './pages/admin/Dashboard';
import ManagerDashboard from './pages/manager/Dashboard';
import SalesManagerDashboard from './pages/salesmanager/Dashboard';
import Team from './pages/admin/Team';
import AddTeam from './pages/admin/AddTeam'
import AddClient from './pages/admin/AddClient';
import Client from './pages/admin/Client';
import MManager from './pages/admin/MManager';
import SalesManager from './pages/admin/SalesManager';
import Rep from './pages/admin/Rep';
import AddRep from './pages/admin/AddRep';

import AddMManager from './pages/admin/AddMManager';
import AddSalesManager from './pages/admin/AddSalesManager';

import AddQuiz from './pages/admin/AddQuiz';
import GeneralSetting from './pages/admin/GeneralSetting';

import Quiz from './pages/admin/Quiz';

import ProtectedRoute from './ProtectedRoute';
import ManagerProtectedRoute from './ManagerProtectedRoute';
import UserProtectedRoute from './UserProtectedRoute';
import PreventBackWithConfirmation from './DisableBackButton';


const router = createBrowserRouter([
  {
    path: "/salesmanager",
    element: (<ManagerProtectedRoute><AdminDashboard /></ManagerProtectedRoute>),
    children: [
      {
        path: "dashboard",
        element: <SalesManagerDashboard />
      },
      {
        path: "dashboard/:zone",
        element: <Zone />
      },
      {
        path: "zone/:zone",
        element: <Zone />
      },
    ]
  },
  {
    path: "/manager",
    element: (<ManagerProtectedRoute><AdminDashboard /></ManagerProtectedRoute>),
    children: [
      {
        path: "dashboard",
        element: <ManagerDashboard />
      },
      {
        path: "dashboard/:zone",
        element: <Zone />
      },
      {
        path: "zone/:zone",
        element: <Zone />
      },
    ]
  },
  {
    path: "/admin-panel",
    element: (<ProtectedRoute><AdminDashboard /></ProtectedRoute>),
    children: [{
      path: "dashboard",
      element: <Dashboard />
    }, {
      path: "team",
      element: <Team />
    }, {
      path: "team/add",
      element: <AddTeam />
    }, {
      path: "client",
      element: <Client />
    },
    {
      path: "client/add",
      element: <AddClient />
    },
    {
      path: "client/add/:id",
      element: <AddClient />
    }, {
      path: "m-manager",
      element: <MManager />
    }, {
      path: "m-manager/add",
      element: <AddMManager />
    }, {
      path: "m-manager/add/:id",
      element: <AddMManager />
    },
    {
      path: "sales-manager",
      element: <SalesManager />
    },
    {
      path: "sales-manager/add",
      element: <AddSalesManager />
    }, {
      path: "sales-manager/add/:id",
      element: <AddSalesManager />
    }, {
      path: "rep",
      element: <Rep />
    },
    {
      path: "rep/add",
      element: <AddRep />
    }, {
      path: "rep/add/:id",
      element: <AddRep />
    }, {
      path: "quiz",
      element: <Quiz />
    },
    {
      path: "quiz/add",
      element: <AddQuiz />
    }, {
      path: "quiz/add/:id",
      element: <AddQuiz />
    }, {
      path: "setting",
      element: <GeneralSetting />
    }
    ]
  },

  {
    path: "/logout",
    element: <Logout />
  },

  {
    path: "/",
    element: <Login />
  },

  {
    path: "no-quiz",
    element: <UserProtectedRoute><TodayQuizCompleted /></UserProtectedRoute>
  },

  {
    path: "/loader",
    element: <UserProtectedRoute><Loaders /></UserProtectedRoute>
  },
  {
    path: "/welcome",
    element: <UserProtectedRoute><Welcome /></UserProtectedRoute>
  },
  {
    path: "/team-progress",
    element: <UserProtectedRoute><TeamProgress /></UserProtectedRoute>
  },
  {
    path: "/over-all/team-progress",
    element: <UserProtectedRoute><TeamProgress /></UserProtectedRoute>
  },
  {
    path: "/zone/team-progress",
    element: <UserProtectedRoute><TeamProgress /></UserProtectedRoute>
  },
  {
    path: "/zone-progress",
    element: <UserProtectedRoute><ZoneProgress /></UserProtectedRoute>
  },
  {
    path: "/home",
    element: <UserProtectedRoute><Home /></UserProtectedRoute>
  },
  {
    path: "/quiz",
    element: <UserProtectedRoute><QuizStart /></UserProtectedRoute>
  },
  {
    path: "/quiz-complete",
    element: <UserProtectedRoute><QuizComplete /></UserProtectedRoute>
  },
  {
    path: "/admin-panel",
    children: [{
      path: "login",
      element: <AdminLogin />
    }, {
      path: 'forgotpassword',
      element: <Forgotpassword />
    }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;

}

export default App