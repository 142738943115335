import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import Dashboard from '../pages/admin/Dashboard'
import { useSelector } from "react-redux";
import { Outlet } from 'react-router-dom'
import Navbar from '../components/Navbar'
import { loadScript } from '../utils/loadScript'
function AdminDashboard() {

    const user = useSelector((state) => state.user.user); // Access the user object
    const userRole = user.user.role

    return (

        <div className="container-scroller">
            <Header />
            <Sidebar userRole={userRole} />
            <div className="container-fluid page-body-wrapper">
                <Navbar />
                <div className="main-panel">
                    <Outlet />
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard