import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { addClient, getClientByID } from '../../API/api'; // Import the specific API function
import { useSelector, } from "react-redux";
import { useNavigate } from 'react-router-dom';
function AddClient() {
    const user = useSelector((state) => state.user.user); // Access the user object
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        const loadDropdownData = async () => {
            try {
                const clientList = await getClientByID(user.token, id);
                if (clientList.status === 200) {
                    let cData = clientList.data.data;
                    setFormData({
                        name: cData.name,
                        email: cData.email,
                        phone: cData.phone,
                        address: cData.address,
                        password: "",
                    });
                }
            } catch (err) {
                console.error(err);
            }
        };
        loadDropdownData();
    }, [id]);
    const [apierror, setApiErro] = useState();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    })
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: "",
            });
        }
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = "Name is required";
        if (!formData.email) formErrors.email = "Email is required";
        if (!formData.phone) formErrors.phone = "Phone is required";
        if (!formData.address) formErrors.address = "Address is required";
        return formErrors;
    };

    const onClickHandler = async () => {
        navigate("/admin-panel/client");
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            if (!id) {
                formData['password'] = "123";
            }

            let clientAdd = await addClient(user.token, formData, id);
            if (clientAdd.status === 200) {
                if (!id) {
                    setFormData({
                        name: "",
                        email: "",
                        phone: "",
                        address: "",
                        password: "",
                    });
                }
                setApiErro(clientAdd.data.message);
            } else {
                setApiErro(clientAdd.data.message)
            }
        }
    };

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title"> {id ? "Edit Client" : "Add Client"} </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin-panel/client" >Client</Link></li>
                        {/* <li className="breadcrumb-item active" aria-current="page">Form elements</li> */}
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title text-success">{apierror}</h4>

                            <form className="forms-sample" onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="exampleInputUsername1">Name</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email </label>
                                    <input type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputUsername1">Phone no</label>
                                    <input type="text"
                                        className="form-control"
                                        id="exampleInputUsername1"
                                        placeholder="Phone No"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                    {errors.phone && <span style={{ color: "red" }}>{errors.phone}</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputUsername1">Address</label>
                                    <input type="textarea"
                                        className="form-control"
                                        id="exampleInputUsername1"
                                        placeholder="address"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                    {errors.address && <span style={{ color: "red" }}>{errors.address}</span>}
                                </div>
                                <button type="submit" className="btn btn-primary me-2">{(id) ? "Edit" : "Add"}</button>
                                <button className="btn btn-dark" onClick={onClickHandler}>Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddClient