import React, { useEffect, useState } from 'react'
import { storeQuizResult } from '../../API/api'; // Import the specific API function
import { useDispatch, useSelector } from 'react-redux';

import QuizQuestion from './QuizQuestion'
import PointScreen from './PointScreen'
import DisableBackButton from "../../DisableBackButton"
import { nextQuestion, updateAnswer, updateTotalTimeAndAnswer, todayQuizComplete } from '../../store/Slice/quizSlice';
import { useNavigate } from 'react-router-dom';
import Loaders from './Loaders';
function Quiz() {
    const [loading, setLoading] = useState(false);
    const userData = useSelector((state) => state.user.user); // Access the user object
    const [isDisabled, setIsDisabled] = useState(true); // Initially disabled
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState(null);
    const [className, setClassName] = useState("");
    const dispatch = useDispatch();
    const { questions, currentIndex, quizcompletToday } = useSelector((state) => state.quiz);
    const [intervalId, setIntervalId] = useState(null); // Store interval ID
    const [currentTimer, setCurrentTimer] = useState(1); // Track which timer is active
    const [timeLeft, setTimeLeft] = useState(0); // Track time left for the current timer
    const [point, setPoint] = useState(0);

    const timerBonus = [
        { timeRange: [0, 5], points: 150 },
        { timeRange: [5, 10], points: 130 },
        { timeRange: [10, 15], points: 110 },
        { timeRange: [15, Infinity], points: -10 },
    ];

    function calculatePoints(responseTime) {
        for (let bonus of timerBonus) {
            if (responseTime > bonus.timeRange[0] && responseTime <= bonus.timeRange[1]) {
                return bonus.points;
            }
        }
        return -10; // Default in case something goes wrong
    }
    const timerDurations = {
        1: 15, // First timer: 15 seconds
        2: 3,  // Second timer: 3 seconds
        3: 3,  // Third timer: 3 seconds
    };

    useEffect(() => {
        // Set the initial time for the active timer
        clearInterval(intervalId);
        setTimeLeft(timerDurations[currentTimer]);
        // Start the countdown for the active timer
        const newIntervalId = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev > 1) {
                    return prev - 1;
                } else {
                    // When the timer ends, clear the interval and switch to the next timer
                    clearInterval(newIntervalId);
                    if (currentTimer === 1) {
                        dispatch(updateAnswer({ index: currentIndex, selectedOption: "", timetaken: 15, point: -10 }));
                        setPoint(-10);
                        setCurrentTimer(2);
                    }

                    switchToNextTimer();
                    return 0;
                }
            });
        }, 1000);
        setIntervalId(newIntervalId);
        return () => clearInterval(newIntervalId); // Cleanup on component unmount or timer change
    }, [currentTimer]);

    const switchToNextTimer = () => {
        setCurrentTimer((prev) => {
            // Loop back to the first timer after the third one
            if (prev === 3) {
                dispatch(nextQuestion());
                setClassName("");
                setActiveButton(null);
                setIsDisabled(true);
                return 1;
            }
            if (prev + 1 === 2) {
                setIsDisabled(false);
            }
            return prev + 1;
        });
    };

    const handleOptionSelect = async (selectedOption) => {
        if (currentTimer !== 2) {
            setActiveButton(selectedOption)
            let timetaken = timerDurations[currentTimer] - timeLeft;
            const selectOption = currentQuestion[`option${selectedOption}`]
            const answer = currentQuestion['answer']

            let receivePoint = 0;
            if (selectOption === answer) {
                setClassName("bg-green");
                receivePoint = calculatePoints(timetaken);
                setPoint(receivePoint)
            } else {
                setClassName("bg-red");
                receivePoint = -10;
                setPoint(-10);
            }

            dispatch(updateAnswer({ index: currentIndex, selectedOption, timetaken, point: receivePoint }));
            clearInterval(intervalId);
            switchToNextTimer();
        }


    };
    let currentQuestion = {};
    const [storeFlag, setStoreFlag] = useState(true);
    if (currentIndex >= questions.length) {
        async function storeData() {
            let qArray = [];
            let totalPoint = 0;
            let totalTime = 0;
            let totalCorrectAnswer = 0;
            let totalInCorrectAnswer = 0;
            for (let q in questions) {
                totalTime += questions[q]["timetaken"];
                if (questions[q]["answer"] === questions[q]['selectedOption']) {
                    totalCorrectAnswer++;
                } else {
                    totalInCorrectAnswer++;
                }
                totalPoint += questions[q]["point"]; // Add if positive or zero
                qArray.push({
                    "question": questions[q]["question"],
                    "answer": questions[q]["answer"],
                    "selectedOption": questions[q]["selectedOption"],
                    "timetaken": questions[q]["timetaken"],
                    "point": questions[q]["point"],
                    "qID": questions[q]["_id"]
                })
            }
            let finalData = {
                "uID": userData.user._id,
                "Point": totalPoint,
                "cID": userData.user.cID,
                "quizDate": new Date(),
                "questions": qArray,
                "correctAnswer": totalCorrectAnswer,
                "inCorrectAnswer": totalInCorrectAnswer
            };
            // setLoading(true);
            dispatch(updateTotalTimeAndAnswer({ totalTime, totalCorrectAnswer }));
            await storeQuizResult(userData.token, finalData)
            // setLoading(false);
            navigate("/quiz-complete");
        }
        if (!quizcompletToday) {
            dispatch(todayQuizComplete());
            storeData();
        } else {
            navigate("/quiz-complete");
        }

    } else {
        currentQuestion = questions[currentIndex];
    }
    // navigate("/quiz-complete");
    return (
        <div>
            {loading && <Loaders />}
            {currentTimer !== 3 ? <QuizQuestion
                currentQuestion={currentQuestion}
                handleOptionSelect={handleOptionSelect}
                activeButton={activeButton}
                className={className}
                timeLeft={timeLeft}
                isDisabled={isDisabled}
            /> : <PointScreen point={point} />}
            <DisableBackButton />
        </div>

    )
}

export default Quiz