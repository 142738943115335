import React from 'react'
import { useState, CSSProperties } from "react";


import { Loader } from "react-clip-loader";
import "react-clip-loader/dist/index.css";



function Loaders() {
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    return (
        <div className="overlay">
            <div className="spinner"></div>
            <p className="loading-text">Loading, please wait...</p>
        </div>
    )
}

export default Loaders